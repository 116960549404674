import { CurrencyPipe } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { MatDatepicker } from "@angular/material/datepicker";
import { ActivatedRoute } from "@angular/router";
import { ChartOptions } from "chart.js";
import * as pluginDataLabels from "chartjs-plugin-datalabels";
import * as moment from "moment";
import { Moment } from "moment";
import { Label } from "ng2-charts";
import { BehaviorSubject } from "rxjs";
import { delay } from "rxjs/operators";
import { CotaMeta } from "src/app/_models/benchmark.models";
import { Retorno } from "src/app/_models/carteira.model";
import { GroupByPipe } from "src/app/_pipes/groupBy.pipe";
import { ResumoNomeFundoPipe } from "src/app/_pipes/resumoNomeFundo.pipe";
import { SortPipe } from "src/app/_pipes/sort.pipe";
import { AuthService } from "src/app/_services/auth.service";
import { CarteiraService } from "src/app/_services/carteira.service";
import { ClienteService } from "src/app/_services/cliente.service";
import { environment } from "src/environments/environment";

export const MY_FORMATS = {
  parse: {
    dateInput: "MM/YYYY",
  },
  display: {
    dateInput: "MM/YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};
@Component({
  selector: "app-relatorio-retornos",
  templateUrl: "./relatorio-retornos.component.html",
  styleUrls: ["./relatorio-retornos.component.scss"],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class RelatorioRetornosComponent implements OnInit {
  @Input()
  laminaResumo: Boolean = false;

  @Input() meta: CotaMeta;

  @Input()
  relatorioDinamico: Boolean = this.laminaResumo || false;

  @Output()
  loadingStatus = new EventEmitter<boolean>();

  dataEntrada$ = new BehaviorSubject<string>(null);
  @Input() set dataEntrada(value: string) {
    this.dataEntrada$.next(value);
  }

  minDate: Date;
  public pieChartLabels: Label[];
  public pieChartData: number[];

  atual = moment();
  date = new FormControl(moment());
  tipoFundo = new FormControl(null);
  columnsToDisplay = [
    "fundo",
    //"grupo",
    "saldoAnterior",
    "aplicacoes",
    "resgates",
    "saldoAtual",
    "participacao",
    "retorno",
    "percentualRetorno",
    "efetivo",
  ];
  columnsToDisplayResumo = [
    "fundo",
    //"grupo",
    "saldoAnterior",
    "aplicacoes",
    "resgates",
    "saldoAtual",
    "retorno",
    "percentualRetorno",
    "efetivo",
  ];

  pesosOrdem = [
    { grupo: "Fundo de Renda Fixa", peso: 1 },
    { grupo: "Fundo de Renda Variável", peso: 2 },
    { grupo: "Títulos Públicos", peso: 9998 },
    { grupo: "Fundo Estressado", peso: 9999 },
  ];

  widthColuna = `${75 / (this.columnsToDisplay.length - 1)}%`;
  registros: Retorno[] = [];
  registros_agrupados: any[] = [];
  idCliente: number;
  complementoTitulo = "";

  totalSaldoAnterior = 0;
  totalSaldoAtual = 0;
  totalAplicacoes = 0;
  totalResgates = 0;
  totalRetorno = 0;
  totalEfetivo = 0;
  totalRetornoPercentual = 0;
  totalRR = 0;

  rentabilidadeMedia = 0;
  atingimentoMeta = 0;

  graficoDistribuicaoFundos: any[];

  agrupados = [];

  zoom = new FormControl(1);

  notLoading: boolean = true;
  public barChartPlugins = [pluginDataLabels];
  public pieChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    plugins: {
      datalabels: {
        anchor: "center",
        align: "center",
        font: {
          weight: "bold",
        },
        textStrokeColor: "#FFF",
        textStrokeWidth: 6,
        formatter: (value) => {
          //console.log(this.totalSaldoAtual);
          if (value / this.totalSaldoAtual > 0.05) {
            return new CurrencyPipe("pt-BR").transform(value, "BRL");
          }
          return "";
        },
      },
    },
  };

  constructor(
    private carteiraService: CarteiraService,
    private route: ActivatedRoute,
    private clienteService: ClienteService,
    private authService: AuthService
  ) {
    this.idCliente = +route.snapshot.paramMap.get("idCliente");
  }

  ngOnInit() {
    this.minDate = new Date(2021, 12, 31);
    if (this.laminaResumo) {
      this.columnsToDisplay = [
        "fundo",
        //"grupo",
        "saldoAtual",
        "percentualRetorno",
        "meta",
        "atingimento",
      ];
    }
    if (!this.relatorioDinamico && !this.laminaResumo) {
      window.onafterprint = (e) => this.authService.willPrint$.next(false);
    }
    if (!this.relatorioDinamico && !this.laminaResumo) {
      this.authService.willPrint$.pipe(delay(500)).subscribe((willPrint) => {
        if (willPrint) {
          window.print();
        }
      });
    } else {
      this.dataEntrada$.subscribe((value) => {
        console.log("mudou aqui");
        this.date.setValue(moment(value, "DD/MM/YYYY"));
        this.retrieveRetornos(
          moment(value, "DD/MM/YYYY"),
          this.tipoFundo.value
        );
      });
    }
    if (this.idCliente) {
      this.clienteService.buscarClientePorId(this.idCliente).subscribe((c) => {
        this.complementoTitulo = ` - Cliente: ${c.nome}`;
      });
    }
  }

  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue.startOf("month"));
    // this.retrieveRetornos(this.date.value, this.tipoFundo.value);
  }

  chosenMonthHandler(
    normalizedMonth: Moment,
    datepicker: MatDatepicker<Moment>
  ) {
    const ctrlValue = this.date.value;
    ctrlValue.month(normalizedMonth.month());
    this.date.setValue(ctrlValue.startOf("month"));
    if (datepicker) {
      datepicker.close();
    }
    this.retrieveRetornos(this.date.value, this.tipoFundo.value);
  }

  chosenFundTypeHandler(event: any) {
    this.tipoFundo.setValue(event.value);
    this.retrieveRetornos(this.date.value, event.value.toString());
  }

  retrieveRetornos(date: Moment, tipoFundo: String) {
    this.loadingStatus.emit(false);
    this.notLoading = false;
    this.carteiraService
      .relatorioRetornos(
        this.idCliente,
        date,
        tipoFundo ? tipoFundo.toString() : ""
      )
      .subscribe((v) => {
        this.notLoading = true;
        this.loadingStatus.emit(true);
        this.graficoDistribuicaoFundos = v.map((c) => ({
          name: new ResumoNomeFundoPipe().transform(c.nomeFundo),
          value: c.saldoAtual,
        }));

        this.pieChartLabels = new SortPipe()
          .transform(v, "saldoAtual")
          .map((c) => new ResumoNomeFundoPipe().transform(c.nomeFundo));
        this.pieChartData = new SortPipe()
          .transform(v, "saldoAtual")
          .map((c) => c.saldoAtual);

        const total = v.reduce((a, b) => a + b.saldoAtual, 0);
        this.registros = v.map((value) => ({
          ...value,
          participacao: value.saldoAtual / total,
        }));

        this.totalAplicacoes = this.registros.reduce(
          (acc, a) => acc + a.aplicacoes,
          0
        );
        this.totalResgates = this.registros.reduce(
          (acc, a) => acc + a.resgates,
          0
        );
        this.totalRetorno = this.registros.reduce(
          (acc, a) => acc + a.retorno,
          0
        );

        this.totalSaldoAnterior = this.registros.reduce(
          (acc, a) => acc + a.saldoAnterior,
          0
        );
        this.totalSaldoAtual = this.registros.reduce(
          (acc, a) => acc + a.saldoAtual,
          0
        );

        //Refeito o calculo conforme reunião dia 12/03/2021
        // this.totalEfetivo = this.registros.reduce(
        //   (acc, a) => acc + a.efetivo * a.participacao,
        //   0
        // );

        /*
        const calculo_1 = ((this.totalSaldoAtual + this.totalResgates - this.totalAplicacoes) / this.totalSaldoAnterior) -1;

        const calculo_2 = (this.totalSaldoAtual/(this.totalSaldoAnterior + this.totalAplicacoes - this.totalResgates)) - 1;

        this.totalEfetivo = (calculo_1 + calculo_2)/2;
         */

        const retorno =
          this.totalSaldoAtual -
          (this.totalSaldoAnterior + this.totalAplicacoes - this.totalResgates);

        const rr =
          (retorno * this.totalResgates) /
          (this.totalResgates + this.totalSaldoAtual);

        this.totalEfetivo =
          this.totalSaldoAtual /
            (this.totalSaldoAnterior +
              this.totalAplicacoes -
              this.totalResgates +
              rr) -
          1;

        this.totalRetornoPercentual = this.registros.reduce(
          (acc, a) => acc + a.retornoPercentual * a.participacao,
          0
        );

        if (this.laminaResumo) {
          this.rentabilidadeMedia =
            v.reduce((acc, item) => acc + item.saldoAtual * item.efetivo, 0) /
            total;

          //this.atingimentoMeta = (v.reduce((acc, item) => acc + (item.saldoAtual * (item.efetivo / (this.meta.percentualMes || 0))),0) / total) * 100 ;

          this.atingimentoMeta =
            (this.totalEfetivo / this.meta.percentualMes) * 100;
        }
        const agrupados = new GroupByPipe().transform(this.registros, "grupo");

        this.agrupados = agrupados.map((grupo) => {
          const { key, value } = grupo;
          const saldoGrupo = value.reduce((acc, a) => acc + a.saldoAtual, 0);
          const saldoAnteriorGrupo = value.reduce(
            (acc, a) => acc + a.saldoAnterior,
            0
          );
          const aplicacoesGrupo = value.reduce(
            (acc, a) => acc + a.aplicacoes,
            0
          );
          const resgatesGrupo = value.reduce((acc, a) => acc + a.resgates, 0);
          const retornoGrupo =
            saldoGrupo - (saldoAnteriorGrupo + aplicacoesGrupo - resgatesGrupo);
          const totalRRGrupo =
            (retornoGrupo * resgatesGrupo) / (resgatesGrupo + saldoGrupo);

          return {
            grupo: key,
            totalAplicacoes: value.reduce((acc, a) => acc + a.aplicacoes, 0),
            totalResgates: value.reduce((acc, a) => acc + a.resgates, 0),
            totalRetorno: value.reduce((acc, a) => acc + a.retorno, 0),
            totalSaldoAnterior: value.reduce(
              (acc, a) => acc + a.saldoAnterior,
              0
            ),
            totalSaldoAtual: saldoGrupo,

            //Errado
            // totalEfetivo: value.reduce(
            //   (acc, a) => acc + a.efetivo * (a.saldoAtual / saldoGrupo),
            //   0
            // ),

            totalEfetivo:
              saldoGrupo /
                (saldoAnteriorGrupo +
                  aplicacoesGrupo -
                  resgatesGrupo +
                  totalRRGrupo) -
              1,
            totalRetornoPercentual: value.reduce(
              (acc, a) => acc + a.retornoPercentual * a.participacao,
              0
            ),
          };
        });
      });
  }

  sortFn = (a, b) => {
    return (
      (this.pesosOrdem.find((c) => c.grupo === a.key)?.peso || 5) -
      (this.pesosOrdem.find((c) => c.grupo === b.key)?.peso || 5)
    );
  };

  print() {
    this.authService.willPrint$.next(true);
  }

  abrirCadprev(tipo: string) {
    const url = `${environment.PATH_API}/facilitadores/cadprev/${tipo}/${
      this.idCliente
    }?mesReferencia=${moment(this.date.value).format("MM-YYYY")}`;
    this.authService.openWindowWithAuth(url);
  }
}
