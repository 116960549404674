<h1 class="title">Benchmark</h1>
<div>
  <div class="acoes">
    <mat-form-field>
      <input #in (input)="datasource.queryBy({search: in.value})" matInput placeholder="Filtro">
    </mat-form-field>

    <button routerLink="novo" mat-raised-button color="accent">
      <mat-icon>playlist_add</mat-icon> Novo Benchmark
    </button>
  </div>

  <table mat-table [dataSource]="datasource">
    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></tr>
    <ng-container matColumnDef="nome">
      <th mat-header-cell *matHeaderCellDef>Nome</th>
      <td mat-cell *matCellDef="let benchmark">
        <span class="mobile-label">Nome:</span>
        <a [routerLink]="['editar', benchmark.idBench]">{{ benchmark.nome }}</a>
      </td>
    </ng-container>
    <ng-container matColumnDef="data">
      <th mat-header-cell *matHeaderCellDef>Data</th>
      <td mat-cell *matCellDef="let benchmark">
        <span class="mobile-label">Data:</span>
        {{ benchmark.data | date: "dd/MM/yyyy" }}
      </td>
    </ng-container>
    <ng-container matColumnDef="perc12">
      <th mat-header-cell *matHeaderCellDef>Percentual 12M</th>
      <td mat-cell *matCellDef="let benchmark">
        <span class="mobile-label">Percentual 12M:</span>
        {{ benchmark.lastPerc12 | number: "1.4-10" }}%
      </td>
    </ng-container>
    <ng-container matColumnDef="perc24">
      <th mat-header-cell *matHeaderCellDef>Percentual 24M</th>
      <td mat-cell *matCellDef="let benchmark">
        <span class="mobile-label">Percentual 24M:</span>
        {{ benchmark.lastPerc24 | number: "1.4-10" }}%
      </td>
    </ng-container>
    <ng-container matColumnDef="percAno">
      <th mat-header-cell *matHeaderCellDef>Percentual Ano</th>
      <td mat-cell *matCellDef="let benchmark">
        <span class="mobile-label">Percentual Ano:</span>
        {{ benchmark.lastPercAno | number: "1.4-10" }}%
      </td>
    </ng-container>
    <ng-container matColumnDef="apagar">
      <th mat-header-cell *matHeaderCellDef style="text-align: right;">Apagar</th>
      <td mat-cell *matCellDef="let benchmark" style="text-align: right;">
        <span class="mobile-label">Apagar:</span>
        <button mat-raised-button matTooltip="Remover benchmark" (click)="removerBenchmark(benchmark)" color="warn" style="text-align: right;">
          <i class="fas fa-times"></i>
        </button>
      </td>
    </ng-container>
  </table>
  <div class="spinner-container" *ngIf="datasource.loading$ | async">
    <mat-spinner [diameter]="30" [strokeWidth]="5"></mat-spinner>
  </div>

  <mat-paginator *ngIf="datasource.page$ | async as page"
  [length]="page.totalElements" [pageSize]="page.size"
  [pageIndex]="page.number" [hidePageSize]="true" 
  (page)="datasource.fetch($event.pageIndex)">
</mat-paginator>
</div>
