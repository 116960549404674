<div style="position: fixed; top: 0; left: 0; width: 100%; height: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center; background-color: rgba(255,255,255,0.8);" *ngIf="loading">
  <mat-spinner diameter="40" style="margin: 0 auto;"></mat-spinner>
  <h4>Carregando...</h4>
</div>
<h1 class="title">Índices da carteira</h1>
<div class="hidePrint" style="display: flex; flex-flow: row;">
  <form style="flex:1" class="hidePrint">
    <mat-form-field>
       <input
        matInput
        [matDatepicker]="dp"
        [max]="atual"
        placeholder="Mês/Ano"
        [formControl]="date"
min="2022-01-01"
      />
      <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
      <mat-datepicker
        #dp
        startView="multi-year"
        (yearSelected)="chosenYearHandler($event)"
        (monthSelected)="chosenMonthHandler($event, dp)"
        panelClass="example-month-picker"
      >
      </mat-datepicker>
    </mat-form-field>
    <button
      class="hidePrint"
      (click)="print()"
      mat-flat-button
      color="primary"
    >
      Imprimir
    </button>
  </form>
  </div>
<table mat-table [dataSource]="indices" *ngIf="indices.length > 0">
    <ng-container matColumnDef="cnpj">
      <th mat-header-cell *matHeaderCellDef class="left"> CNPJ Fundo </th>
      <td mat-cell *matCellDef="let element" class="left"> {{element.cnpj | mask: '00.000.000/0000-00'}} </td>
    </ng-container>
    <ng-container matColumnDef="nome">
      <th mat-header-cell *matHeaderCellDef style="width: 40%; text-align: left"> Nome </th>
      <td mat-cell *matCellDef="let element" style="width: 40%; text-align: left" class="small"> {{element.nome}} </td>
    </ng-container>
    <ng-container matColumnDef="saldo">
      <th mat-header-cell *matHeaderCellDef> Saldo </th>
      <td mat-cell *matCellDef="let element" class="small"> {{element.saldo | currency: 'BRL'}} </td>
    </ng-container>
    <ng-container matColumnDef="benchmark">
      <th mat-header-cell *matHeaderCellDef> Benchmark </th>
      <td mat-cell *matCellDef="let element" class="small"> {{element.benchmark}} </td>
    </ng-container>
    <ng-container matColumnDef="retorno">
      <th mat-header-cell *matHeaderCellDef> Retorno </th>
      <td mat-cell *matCellDef="let element"> {{element.retorno | percent:'1.6'}} </td>
    </ng-container>
    <ng-container matColumnDef="rankingRetorno" >
      <th mat-header-cell *matHeaderCellDef class="center" mat-sort-header> Rank Retorno </th>
      <td mat-cell *matCellDef="let element" class="center"> {{element.rankingRetorno}} </td>
    </ng-container>
    <ng-container matColumnDef="desvpad" >
      <th mat-header-cell *matHeaderCellDef> D. Padrão </th>
      <td mat-cell *matCellDef="let element"> {{element.desvpad | percent:'1.6'}} </td>
    </ng-container>
    <ng-container matColumnDef="rankingDesvpad">
      <th mat-header-cell *matHeaderCellDef class="center" mat-sort-header> Rank D. Padrão </th>
      <td mat-cell *matCellDef="let element"  class="center"> {{element.rankingDesvpad}} </td>
    </ng-container>
    <ng-container matColumnDef="sharpe">
      <th mat-header-cell *matHeaderCellDef> Sharpe </th>
      <td mat-cell *matCellDef="let element"> {{element.sharpe | percent:'1.6'}} </td>
    </ng-container>
    <ng-container matColumnDef="rankingSharpe">
      <th mat-header-cell *matHeaderCellDef class="center" mat-sort-header> Rank Sharpe</th>
      <td mat-cell *matCellDef="let element" class="center"> {{element.rankingSharpe}} </td>
    </ng-container>
    <ng-container matColumnDef="var">
      <th mat-header-cell *matHeaderCellDef> VAR</th>
      <td mat-cell *matCellDef="let element"> {{element.var | percent:'1.6'}} </td>
    </ng-container>
    <ng-container matColumnDef="bvar">
      <th mat-header-cell *matHeaderCellDef> B.VAR</th>
      <td mat-cell *matCellDef="let element"> {{(element.var * 0.85)*100 | percent:'1.6'}} </td>
    </ng-container>
    <ng-container matColumnDef="vol12">
      <th mat-header-cell *matHeaderCellDef> VOL</th>
      <td mat-cell *matCellDef="let element"> {{element.vol12 | percent:'1.6'}} </td>
    </ng-container>
    <ng-container matColumnDef="vol6">
      <th mat-header-cell *matHeaderCellDef> VOL 6M</th>
      <td mat-cell *matCellDef="let element"> {{element.vol6 | percent:'1.6'}} </td>
    </ng-container>
    <ng-container matColumnDef="vol3">
      <th mat-header-cell *matHeaderCellDef> VOL 3M</th>
      <td mat-cell *matCellDef="let element"> {{element.vol3 | percent:'1.6'}} </td>
    </ng-container>
    <ng-container matColumnDef="rankingVar">
      <th mat-header-cell *matHeaderCellDef class="center" mat-sort-header> Rank VAR </th>
      <td mat-cell *matCellDef="let element" class="center"> {{element.rankingVar}} </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr mat-row *matRowDef="let row; columns: columns;"></tr>
  </table>

  <div style="height: 600px;" *ngIf="indices.length > 0">
    <h2>Rentabilidade x Desvio padrão</h2>
    <ngx-charts-bubble-chart
      [scheme]="colorSchema"
      [results]="chartDataDesvPad"
      [xAxis]="true"
      [yAxis]="true"
      [showXAxisLabel]="true"
      [showYAxisLabel]="true"
      xAxisLabel="Rentabilidade"
      yAxisLabel="Desvio padrão"
      [autoScale]="true"
    >
    </ngx-charts-bubble-chart>
  </div>

  <div style="height: 600px;" *ngIf="indices.length > 0">
    <h2>Rentabilidade x Sharpe</h2>
    <ngx-charts-bubble-chart
      [scheme]="colorSchema"
      [results]="chartData"
      [xAxis]="true"
      [yAxis]="true"
      [showXAxisLabel]="true"
      [showYAxisLabel]="true"
      xAxisLabel="Rentabilidade"
      yAxisLabel="Sharpe"
      [autoScale]="true"
    >
    </ngx-charts-bubble-chart>
  </div>
  <h2 *ngIf="indices.length > 0">Por Benchmark</h2>
  <div *ngFor="let item of indices | groupBy:'benchmark'">
    <h4>{{item.key != 'null' ? item.key : 'Não definido'}}</h4>
    <table mat-table [dataSource]="item.value" [hidden]="!indices">
      <ng-container matColumnDef="cnpj">
        <th mat-header-cell *matHeaderCellDef class="left"> CNPJ Fundo </th>
        <td mat-cell *matCellDef="let element" class="left"> {{element.cnpj | mask: '00.000.000/0000-00'}} </td>
      </ng-container>
      <ng-container matColumnDef="nome">
        <th mat-header-cell *matHeaderCellDef style="width: 40%; text-align: left"> Nome </th>
        <td mat-cell *matCellDef="let element" style="width: 40%; text-align: left" class="small"> {{element.nome}} </td>
      </ng-container>
      <ng-container matColumnDef="saldo">
        <th mat-header-cell *matHeaderCellDef> Saldo </th>
        <td mat-cell *matCellDef="let element" class="small"> {{element.saldo | currency: 'BRL'}} </td>
      </ng-container>
      <ng-container matColumnDef="benchmark">
        <th mat-header-cell *matHeaderCellDef> Benchmark </th>
        <td mat-cell *matCellDef="let element" class="small"> {{element.benchmark}} </td>
      </ng-container>
      <ng-container matColumnDef="retorno">
        <th mat-header-cell *matHeaderCellDef> Retorno </th>
        <td mat-cell *matCellDef="let element"> {{element.retorno | percent:'1.6'}} </td>
      </ng-container>
      <ng-container matColumnDef="rankingRetorno" >
        <th mat-header-cell *matHeaderCellDef class="center" mat-sort-header> Rank Retorno </th>
        <td mat-cell *matCellDef="let element" class="center"> {{element.rankingRetorno}} </td>
      </ng-container>
      <ng-container matColumnDef="desvpad" >
        <th mat-header-cell *matHeaderCellDef> D. Padrão </th>
        <td mat-cell *matCellDef="let element"> {{element.desvpad | percent:'1.6'}} </td>
      </ng-container>
      <ng-container matColumnDef="rankingDesvpad">
        <th mat-header-cell *matHeaderCellDef class="center" mat-sort-header> Rank D. Padrão </th>
        <td mat-cell *matCellDef="let element"  class="center"> {{element.rankingDesvpad}} </td>
      </ng-container>
      <ng-container matColumnDef="sharpe">
        <th mat-header-cell *matHeaderCellDef> Sharpe </th>
        <td mat-cell *matCellDef="let element"> {{element.sharpe | percent:'1.6'}} </td>
      </ng-container>
      <ng-container matColumnDef="rankingSharpe">
        <th mat-header-cell *matHeaderCellDef class="center" mat-sort-header> Rank Sharpe</th>
        <td mat-cell *matCellDef="let element" class="center"> {{element.rankingSharpe}} </td>
      </ng-container>
      <ng-container matColumnDef="var">
        <th mat-header-cell *matHeaderCellDef> VAR 12M</th>
        <td mat-cell *matCellDef="let element"> {{element.var | percent:'1.6'}} </td>
      </ng-container>
      <ng-container matColumnDef="vol12">
        <th mat-header-cell *matHeaderCellDef> VOL 12M</th>
        <td mat-cell *matCellDef="let element"> {{element.vol12 | percent:'1.6'}} </td>
      </ng-container>
      <ng-container matColumnDef="vol6">
        <th mat-header-cell *matHeaderCellDef> VOL 6M</th>
        <td mat-cell *matCellDef="let element"> {{element.vol6 | percent:'1.6'}} </td>
      </ng-container>
      <ng-container matColumnDef="vol3">
        <th mat-header-cell *matHeaderCellDef> VOL 3M</th>
        <td mat-cell *matCellDef="let element"> {{element.vol3 | percent:'1.6'}} </td>
      </ng-container>
      <ng-container matColumnDef="rankingVar">
        <th mat-header-cell *matHeaderCellDef class="center" mat-sort-header> Rank VAR </th>
        <td mat-cell *matCellDef="let element" class="center"> {{element.rankingVar}} </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="columns"></tr>
      <tr mat-row *matRowDef="let row; columns: columns;"></tr>
    </table>
  </div>

  <h2 *ngIf="indices.length > 0">Por artigo</h2>
  <div *ngFor="let item of provisao | groupBy:'legislacao'">
    <h4>{{item.key != 'null' ? item.key : 'Não definido'}}</h4>
    <table mat-table [dataSource]="item.value" [hidden]="!indices">
      <ng-container matColumnDef="cnpj">
        <th mat-header-cell *matHeaderCellDef class="left"> CNPJ Fundo </th>
        <td mat-cell *matCellDef="let element" class="left"> {{element.cnpj | mask: '00.000.000/0000-00'}} </td>
      </ng-container>
      <ng-container matColumnDef="nome">
        <th mat-header-cell *matHeaderCellDef style="width: 40%; text-align: left"> Nome </th>
        <td mat-cell *matCellDef="let element" style="width: 40%; text-align: left" class="small"> {{element.nome}} </td>
      </ng-container>
      <ng-container matColumnDef="saldo">
        <th mat-header-cell *matHeaderCellDef> Saldo </th>
        <td mat-cell *matCellDef="let element" class="small"> {{element.saldo | currency: 'BRL'}} </td>
      </ng-container>
      <ng-container matColumnDef="benchmark">
        <th mat-header-cell *matHeaderCellDef> Benchmark </th>
        <td mat-cell *matCellDef="let element" class="small"> {{element.benchmark}} </td>
      </ng-container>
      <ng-container matColumnDef="retorno">
        <th mat-header-cell *matHeaderCellDef> Retorno </th>
        <td mat-cell *matCellDef="let element"> {{element.retorno | percent:'1.6'}} </td>
      </ng-container>
      <ng-container matColumnDef="rankingRetorno" >
        <th mat-header-cell *matHeaderCellDef class="center" mat-sort-header> Rank Retorno </th>
        <td mat-cell *matCellDef="let element" class="center"> {{element.rankingRetorno}} </td>
      </ng-container>
      <ng-container matColumnDef="desvpad" >
        <th mat-header-cell *matHeaderCellDef> D. Padrão </th>
        <td mat-cell *matCellDef="let element"> {{element.desvpad | percent:'1.6'}} </td>
      </ng-container>
      <ng-container matColumnDef="rankingDesvpad">
        <th mat-header-cell *matHeaderCellDef class="center" mat-sort-header> Rank D. Padrão </th>
        <td mat-cell *matCellDef="let element"  class="center"> {{element.rankingDesvpad}} </td>
      </ng-container>
      <ng-container matColumnDef="sharpe">
        <th mat-header-cell *matHeaderCellDef> Sharpe </th>
        <td mat-cell *matCellDef="let element"> {{element.sharpe | percent:'1.6'}} </td>
      </ng-container>
      <ng-container matColumnDef="rankingSharpe">
        <th mat-header-cell *matHeaderCellDef class="center" mat-sort-header> Rank Sharpe</th>
        <td mat-cell *matCellDef="let element" class="center"> {{element.rankingSharpe}} </td>
      </ng-container>
      <ng-container matColumnDef="var">
        <th mat-header-cell *matHeaderCellDef> VAR 12M</th>
        <td mat-cell *matCellDef="let element"> {{element.var | percent:'1.6'}} </td>
      </ng-container>
      <ng-container matColumnDef="vol12">
        <th mat-header-cell *matHeaderCellDef> VOL 12M</th>
        <td mat-cell *matCellDef="let element"> {{element.vol12 | percent:'1.6'}} </td>
      </ng-container>
      <ng-container matColumnDef="vol6">
        <th mat-header-cell *matHeaderCellDef> VOL 6M</th>
        <td mat-cell *matCellDef="let element"> {{element.vol6 | percent:'1.6'}} </td>
      </ng-container>
      <ng-container matColumnDef="vol3">
        <th mat-header-cell *matHeaderCellDef> VOL 3M</th>
        <td mat-cell *matCellDef="let element"> {{element.vol3 | percent:'1.6'}} </td>
      </ng-container>
      <ng-container matColumnDef="rankingVar">
        <th mat-header-cell *matHeaderCellDef class="center" mat-sort-header> Rank VAR </th>
        <td mat-cell *matCellDef="let element" class="center"> {{element.rankingVar}} </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="columns"></tr>
      <tr mat-row *matRowDef="let row; columns: columns;"></tr>
    </table>
  </div>
