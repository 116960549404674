import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BenchmarkService } from './../../../_services/benchmark.service';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { UIService } from 'src/app/_helpers/ui.service';
import { DialogConfirmComponent } from 'src/app/_helpers/dialog-confirm/dialog-confirm.component';
import { Benchmark, BenchmarkQuote } from 'src/app/_models/benchmark.models';

@Component({
  selector: 'app-cadastro-benchmark',
  templateUrl: './cadastro-benchmark.component.html',
  styleUrls: ['./cadastro-benchmark.component.scss']
})
export class CadastroBenchmarkComponent implements OnInit {

  // Para focar novamente no campo
  @ViewChild('percentual12', { static: true }) percentual12: ElementRef;

  idBenchmark: number;

  formBenchmark = new FormGroup({
    nome: new FormControl(null, Validators.required)
  });

  form = new FormGroup({
    data: new FormControl(new Date(), Validators.required),
    percentualAno: new FormControl(null, Validators.required),
    percentual12: new FormControl(null, Validators.required),
    percentual24: new FormControl(null, Validators.required),
  });

  columnsToDisplay = ['data', 'percentual12', 'percentual24', 'percentualAno', 'remover'];
  benchmark: Benchmark;
  quotes: BenchmarkQuote[];
  quotesDatasource: MatTableDataSource<BenchmarkQuote>;
  
  public customPatterns = null;
  //@ts-ignore
  ///customPatterns ='^-?([0-9]+.)?[0-9]{1,4}';

  constructor(
    private route: ActivatedRoute,
    private benchmarkService: BenchmarkService,
    private dialog: MatDialog,
    private uiService: UIService,
    private router: Router) {}

  ngOnInit() {

    this.customPatterns={ '0': { pattern: new RegExp('-|[0-9]') }, '9': { pattern: new RegExp('[0-9]') } }
    
    this.idBenchmark = +this.route.snapshot.paramMap.get('id');
    this.quotes = new Array<BenchmarkQuote>();
    this.quotesDatasource = new MatTableDataSource(this.quotes);
    if (this.idBenchmark) {
      this.benchmarkService.getBenchmark(this.idBenchmark).subscribe(
        (benchmark: Benchmark) => {
          this.benchmark = benchmark;
          this.quotes = benchmark.quotes;
          this.quotesDatasource = new MatTableDataSource(this.quotes);
        }
      );
    }
  }

  salvar() {
    if (!this.idBenchmark) { // Criar novo benchmark
      this.benchmark = {
        nome: this.formBenchmark.get('nome').value,
        quotes: this.quotes
      };
      this.benchmarkService.criarBenchmark(this.benchmark).subscribe(
        response => {
          const dialogRef = this.dialog.open(DialogConfirmComponent, {
            width: '500px',
            data: {text: 'Benchmark salvo com sucesso!'}
          });
          dialogRef.afterClosed().subscribe(
            (result) => this.router.navigate(['benchmark'])
          );
        },
        error => {
          this.uiService.showSnackBar('Erro ao salvar os dados do benchmark', null, 4000);
        }
      );
    } else {
      this.benchmark.quotes = this.quotes; // Atualiza os quotes para salvar
      this.benchmarkService.atualizarBenchmark(this.benchmark).subscribe(
        (response) => {
          const dialogRef = this.dialog.open(DialogConfirmComponent, {
            width: '500px',
            data: {text: 'Benchmark salvo com sucesso!'}
          });
          dialogRef.afterClosed().subscribe(
            (result) => this.router.navigate(['benchmark'])
          );
        },
        error => {
          this.uiService.showSnackBar('Erro ao salvar os dados do benchmark', null, 4000);
        }
      );
    }
  }

  adicionarQuota() {
    let quote: BenchmarkQuote;
    quote = <BenchmarkQuote>{
      data: new Date(this.form.get('data').value),
      perc12: +this.form.get('percentual12').value,
      perc24: +this.form.get('percentual24').value,
      percAno: +this.form.get('percentualAno').value
    };
    if (this.idBenchmark) {
      quote.id =  this.idBenchmark;
    }
    this.quotes.push(quote);
    this.quotesDatasource.data = this.quotes;
    this.form.reset();
    this.form.get('data').setValue(new Date());
    this.percentual12.nativeElement.focus();
  }

  removerQuota = (index) => {
    this.quotes = this.quotes.filter((v, i) => i !== index);
    this.quotesDatasource.data = this.quotes;
  }

}
