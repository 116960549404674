import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { CollectionViewer } from '@angular/cdk/collections';
import { catchError, finalize, map } from 'rxjs/operators';
import { PageRequest, Page } from '../_models/PaginatedResult';
import { Meta } from '../_models/benchmark.models';

export interface MetaQuery{
  search: string
}


@Injectable({
  providedIn: 'root'
})
export class MetaService {

  private metasUrl = `${environment.PATH_API}/meta`;

  constructor(private http: HttpClient) { }

  listarMetas(filter?: any, pagina?: any, registrosPorPagina?: any): Observable<Meta[]> {
    const url = `${this.metasUrl}`;
    let params = new HttpParams();
    if (registrosPorPagina && pagina) {
      params = params.append('n', registrosPorPagina);
      params = params.append('p', pagina);
    }
    if(filter) {
      params = params.append('filtro', filter);
    }
    params = params.append('page', '0');
    params = params.append('limit', '50');
    return this.http.get<Page<Meta>>(url, {params: params}).pipe(
      map(c => c.data)
    );
  }

  listarMetasPaginado(request: PageRequest<Meta>, query: MetaQuery):Observable<Page<Meta>> {
    const url = `${this.metasUrl}`;
    let params = new HttpParams();
    if(query.search) {
      params = params.append('filtro', query.search);
    }
    params = params.append('page', request.page.toString());
    params = params.append('limit', request.size.toString());
    return this.http.get<Page<Meta>>(url, {params: params});
  }

  getMeta(id: number) {
    const url = `${this.metasUrl}/${id}`;
    return this.http.get<Meta>(url);
  }

  getMetasGraficos() {
    const url = `${this.metasUrl}/graficos`;
    return this.http.get<Meta[]>(url);
  }

  criarMeta(meta: Meta) {
    const url = `${this.metasUrl}/`;
    return this.http.post(url, meta);
  }

  atualizarMeta(meta: Meta) {
    const url = `${this.metasUrl}/${meta.idMeta}`;
    return this.http.put(url, meta);
  }

  atualizarMetaGraficos(idMeta, dados) {
    const url = `${this.metasUrl}/${idMeta}/grafico`;
    return this.http.put(url, dados);
  }

  removerMeta(id: Number) {
    const url = `${this.metasUrl}/${id}`;
    return this.http.delete(url);
  }

}


export class MetaListDataSource implements DataSource<Meta> {

  private metaSubject = new BehaviorSubject<Meta[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);

  public loading$ = this.loadingSubject.asObservable();

  constructor(private metaService: MetaService) {}

  connect(collectionViewer: CollectionViewer): Observable<Meta[]> {
      return this.metaSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
      this.metaSubject.complete();
      this.loadingSubject.complete();
  }

  loadMetas(filter = '', sortDirection = 'asc', pageIndex = 0, pageSize = 5) {

      this.loadingSubject.next(true);

      this.metaService.listarMetas(filter, pageIndex, pageSize).pipe(
          catchError(() => of([])),
          finalize(() => this.loadingSubject.next(false))
      )
      .subscribe(metas => this.metaSubject.next(metas));
  }
}
