import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { APRComponent } from "./_components/APR/APR.component";
import { CadastroAPRComponent } from "./_components/APR/CadastroAPR/CadastroAPR.component";
import { AUDESPSPComponent } from "./_components/AUDESPSP/audespsp.component";
import { BaixoRiscoBlackListComponent } from "./_components/BaixoRiscoBlackList/baixo-risco-blacklist.component";
import { BenchmarkComponent } from "./_components/benchmark/benchmark.component";
import { CadastroBenchmarkComponent } from "./_components/benchmark/cadastro-benchmark/cadastro-benchmark.component";
import { NovoCicloVidaComponent } from "./_components/ciclo-vida/simular-ciclo-vida/simular-ciclo-vida.component";
import { AssinadoresComponent } from "./_components/clientes/assinadores/Assinadores.component";
import { CadastroClienteComponent } from "./_components/clientes/cadastro-cliente/cadastro-cliente.component";
import { AtribuicaoDesempenhoComponent } from "./_components/clientes/carteira/atribuicao-desempenho/atribuicao-desempenho.component";
import { CarteiraComponent } from "./_components/clientes/carteira/carteira.component";
import { EnquadramentosComponent } from "./_components/clientes/carteira/enquadramentos/enquadramentos.component";
import { RelatorioRetornosAcumuladoComponent } from "./_components/clientes/carteira/relatorio-retornos-acumulado/relatorio-retornos-acumulado.component";
import { RelatorioRetornosAnualComponent } from "./_components/clientes/carteira/relatorio-retornos-anual/relatorio-retornos-anual.component";
import { RelatorioRetornosFundosAcumuladoComponent } from "./_components/clientes/carteira/relatorio-retornos-fundos-acumulado/relatorio-retornos-fundos-acumulado.component";
import { RelatorioRetornosFundosFinanceiroAcumuladoComponent } from "./_components/clientes/carteira/relatorio-retornos-fundos-financeiro-acumulado/relatorio-retornos-fundos-financeiro-acumulado.component";
import { RelatorioRetornosComponent } from "./_components/clientes/carteira/relatorio-retornos/relatorio-retornos.component";
import { RiscosComponent } from "./_components/clientes/carteira/riscos/riscos.component";
import { ClientesComponent } from "./_components/clientes/clientes.component";
import { ComentariosComponent } from "./_components/Comentarios/Comentarios.component";
import { ComplianceAnbimaComponent } from "./_components/compliance-anbima/compliance-anbima.component";
import { ConjuntosComponent } from "./_components/conjuntos/conjuntos.component";
import { NovoConjuntoComponent } from "./_components/conjuntos/novo-conjunto/novo-conjunto.component";
import { CadastroCredenciamentoComponent } from "./_components/credenciamento/cadastro-credenciamentro/cadastro-credenciamento.component";
import { CredenciamentoComponent } from "./_components/credenciamento/credenciamento.component";
import { RelatorioCredenciamentoComponent } from "./_components/credenciamento/relatorio-credenciamento/relatorio-credenciamento.component";
import { SimulacaoCredenciamentoComponent } from "./_components/credenciamento/simulacao-credenciamento/simulacao-credenciamento.component";
import { CVMBlackListComponent } from "./_components/CVMBlackList/cvm-blacklist.component";
import { DocumentosComponent } from "./_components/Documentos/Documentos.component";
import { CadastroDreiComponent } from "./_components/DREI/cadastro-drei/cadastro-drei.component";
import { DreiComponent } from "./_components/DREI/drei.component";
import { AlteracaoLoteComponent } from "./_components/fundo-investimento/alteracao-lote/alteracao-lote.component";
import { CadastroFundoInvestimentoComponent } from "./_components/fundo-investimento/cadastro-fundo-investimento/cadastro-fundo-investimento.component";
import { FundoInvestimentoComponent } from "./_components/fundo-investimento/fundo-investimento.component";
import { IndicesFundosCarteiraComponent } from "./_components/fundo-investimento/indices-fundos-carteira/indices-fundos-carteira.component";
import { InformesComponent } from "./_components/fundo-investimento/informes/informes.component";
import { RelatorioRentabilidadeFundoComponent } from "./_components/fundo-investimento/relatorio-rentabilidade-fundo/relatorio-rentabilidade-fundo.component";
import { InicioComponent } from "./_components/inicio/inicio.component";
import { ResumoCarteiraComponent } from "./_components/inicio/resumo-carteira/resumo-carteira.component";
import { LaminaResumoComponent } from "./_components/lamina-resumo/lamina-resumo.component";
import { LogarClienteComponent } from "./_components/logar-cliente/logar-cliente.component";
import { LoginComponent } from "./_components/login/login.component";
import { CadastroMetasComponent } from "./_components/metas/cadastro-metas/cadastro-metas.component";
import { MetasComponent } from "./_components/metas/metas.component";
import { TelaGraficosMetaComponent } from "./_components/metas/tela-graficos-meta/tela-graficos-meta.component";
import { OtimizadorComponent } from "./_components/otimizador/otimizador.component";
import { RankingComponent } from "./_components/otimizador/ranking/ranking.component";
import { ResultadoOtimizadorComponent } from "./_components/otimizador/resultado-otimizador/resultado-otimizador.component";
import { PerfilComponent } from "./_components/painel/perfil/perfil.component";
import { RecuperacaoSenhaComponent } from "./_components/recuperacao-senha/recuperacao-senha.component";
import { RelatorioDinamicoClienteComponent } from "./_components/relatorio-dinamico-cliente/relatorio-dinamico-cliente.component";
import { CadastroResolucaoComponent } from "./_components/resolucao/cadastro-resolucao/cadastro-resolucao.component";
import { ResolucaoComponent } from "./_components/resolucao/resolucao.component";
import { SimuladorEnquadramentoComponent } from "./_components/simulador-enquadramento/simulador-enquadramento.component";
import { CadastroUsuarioComponent } from "./_components/usuarios/cadastro-usuario/cadastro-usuario.component";
import { UsuariosComponent } from "./_components/usuarios/usuarios.component";
import { AuthGuard } from "./_guards/auth-guard.service";
import { TipoUsuario } from "./_services/auth.service";

const routes: Routes = [
  { path: "", component: InicioComponent, canActivate: [AuthGuard] },
  { path: "login", component: LoginComponent },
  { path: "recuperacao", component: RecuperacaoSenhaComponent },

  {
    path: "perfil",
    component: PerfilComponent,
    canActivate: [AuthGuard],
  },

  {
    path: "benchmark",
    component: BenchmarkComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "metas/novo",
    component: CadastroMetasComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "metas",
    component: MetasComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "metas/editar/:id",
    component: CadastroMetasComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "benchmark/novo",
    component: CadastroBenchmarkComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "benchmark/editar/:id",
    component: CadastroBenchmarkComponent,
    canActivate: [AuthGuard],
  },

  {
    path: "fundo-investimento",
    component: FundoInvestimentoComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "fundo-investimento/novo",
    component: CadastroFundoInvestimentoComponent,
    canActivate: [AuthGuard],
    data: {
      roles: [TipoUsuario.ADMIN, TipoUsuario.USUARIO],
    },
  },
  {
    path: "fundo-investimento/editar/:cnpj",
    component: CadastroFundoInvestimentoComponent,
    canActivate: [AuthGuard],
    data: {
      roles: [TipoUsuario.ADMIN, TipoUsuario.USUARIO],
    },
  },
  {
    path: "fundo-investimento/edicao-lote",
    component: AlteracaoLoteComponent,
    canActivate: [AuthGuard],
    data: {
      roles: [TipoUsuario.ADMIN, TipoUsuario.USUARIO],
    },
  },
  {
    path: "fundo-investimento/informes/:cnpj",
    component: InformesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "fundo-investimento/rentabilidade/:cnpj",
    component: RelatorioRentabilidadeFundoComponent,
    canActivate: [AuthGuard],
  },

  {
    path: "indices",
    component: TelaGraficosMetaComponent,
    canActivate: [AuthGuard],
  },

  {
    path: "relatorio-dinamico/:idCliente",
    component: RelatorioDinamicoClienteComponent,
    canActivate: [AuthGuard],
  },

  {
    path: "indices-carteira/:idCliente",
    component: IndicesFundosCarteiraComponent,
    canActivate: [AuthGuard],
  },

  {
    path: "atribuicao-desempenho/:idCliente",
    component: AtribuicaoDesempenhoComponent,
    canActivate: [AuthGuard],
  },

  {
    path: "simulador-enquadramento/:idCliente",
    component: SimuladorEnquadramentoComponent,
    canActivate: [AuthGuard],
  },

  {
    path: "indices-carteira",
    component: IndicesFundosCarteiraComponent,
    canActivate: [AuthGuard],
  },

  {
    path: "atribuicao-desempenho",
    component: AtribuicaoDesempenhoComponent,
    canActivate: [AuthGuard],
  },

  {
    path: "simulador-enquadramento",
    component: SimuladorEnquadramentoComponent,
    canActivate: [AuthGuard],
  },

  {
    path: "compliance-anbima",
    component: ComplianceAnbimaComponent,
    canActivate: [AuthGuard],
  },

  {
    path: "compliance-anbima/cliente/:idCliente",
    component: ComplianceAnbimaComponent,
    canActivate: [AuthGuard],
  },

  {
    path: "lamina-resumo/:idCliente",
    component: LaminaResumoComponent,
    canActivate: [AuthGuard],
  },

  {
    path: "clientes/carteira/:idCliente/riscos",
    component: RiscosComponent,
    canActivate: [AuthGuard],
  },

  {
    path: "clientes/carteira/:idCliente/retornos-fundos",
    component: RelatorioRetornosFundosAcumuladoComponent,
    canActivate: [AuthGuard],
  },

  {
    path: "clientes/carteira/:idCliente/retornos-fundos-financeiro",
    component: RelatorioRetornosFundosFinanceiroAcumuladoComponent,
    canActivate: [AuthGuard],
  },

  /** Usuários **/
  {
    path: "usuarios",
    component: UsuariosComponent,
    canActivate: [AuthGuard],
    data: {
      roles: [TipoUsuario.ADMIN, TipoUsuario.ADMIN_CLIENTE],
    },
  },
  {
    path: "usuarios/novo",
    component: CadastroUsuarioComponent,
    canActivate: [AuthGuard],
    data: { roles: [TipoUsuario.ADMIN, TipoUsuario.ADMIN_CLIENTE] },
  },
  {
    path: "usuarios/editar/:id",
    component: CadastroUsuarioComponent,
    canActivate: [AuthGuard],
    data: { roles: [TipoUsuario.ADMIN, TipoUsuario.ADMIN_CLIENTE] },
  },

  /** Clientes **/
  {
    path: "clientes",
    component: ClientesComponent,
    canActivate: [AuthGuard],
    data: { roles: [TipoUsuario.ADMIN, TipoUsuario.USUARIO] },
  },
  {
    path: "clientes/novo",
    component: CadastroClienteComponent,
    canActivate: [AuthGuard],
    data: { roles: [TipoUsuario.ADMIN, TipoUsuario.USUARIO] },
  },
  {
    path: "clientes/editar/:id",
    component: CadastroClienteComponent,
    canActivate: [AuthGuard],
    data: { roles: [TipoUsuario.ADMIN, TipoUsuario.USUARIO] },
  },
  {
    path: "clientes/carteira/:idCliente/retorno",
    component: RelatorioRetornosComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "clientes/carteira/:idCliente/retorno-acumulado",
    component: RelatorioRetornosAcumuladoComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "clientes/carteira/:id/retorno-anual",
    component: RelatorioRetornosAnualComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "clientes/carteira/:idCliente/visao-geral",
    component: ResumoCarteiraComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "clientes/carteira/:idCliente/enquadramentos",
    component: EnquadramentosComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "clientes/carteira/:id",
    component: CarteiraComponent,
    canActivate: [AuthGuard],
  },
  { path: "carteira", component: CarteiraComponent, canActivate: [AuthGuard] },
  {
    path: "otimizador",
    component: OtimizadorComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "otimizador/resultado",
    component: ResultadoOtimizadorComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "otimizador/ranking",
    component: RankingComponent,
    canActivate: [AuthGuard],
  },

  /** CONJUNTO **/
  {
    path: "conjuntos",
    component: ConjuntosComponent,
    canActivate: [AuthGuard],
    data: { roles: [TipoUsuario.ADMIN, TipoUsuario.ADMIN_CLIENTE] },
  },
  {
    path: "conjuntos/novo",
    component: NovoConjuntoComponent,
    canActivate: [AuthGuard],
    data: { roles: [TipoUsuario.ADMIN, TipoUsuario.ADMIN_CLIENTE] },
  },
  {
    path: "conjuntos/editar/:id",
    component: NovoConjuntoComponent,
    canActivate: [AuthGuard],
    data: { roles: [TipoUsuario.ADMIN, TipoUsuario.ADMIN_CLIENTE] },
  },

  // APR
  {
    path: "apr",
    component: APRComponent,
    canActivate: [AuthGuard],
    data: {
      roles: [
        TipoUsuario.ADMIN,
        TipoUsuario.USUARIO,
        TipoUsuario.ADMIN_CLIENTE,
        TipoUsuario.USUARIO_CLIENTE,
      ],
    },
  },
  {
    path: "apr/novo",
    component: CadastroAPRComponent,
    canActivate: [AuthGuard],
    data: {
      roles: [
        TipoUsuario.ADMIN,
        TipoUsuario.ADMIN_CLIENTE,
        TipoUsuario.USUARIO_CLIENTE,
      ],
    },
  },
  {
    path: "resolucao",
    component: ResolucaoComponent,
    canActivate: [AuthGuard],
    data: { roles: [TipoUsuario.ADMIN, TipoUsuario.USUARIO] },
  },
  {
    path: "resolucao/novo",
    component: CadastroResolucaoComponent,
    canActivate: [AuthGuard],
    data: { roles: [TipoUsuario.ADMIN, TipoUsuario.ADMIN_CLIENTE] },
  },
  {
    path: "resolucao/editar/:id",
    component: CadastroResolucaoComponent,
    canActivate: [AuthGuard],
    data: { roles: [TipoUsuario.ADMIN, TipoUsuario.ADMIN_CLIENTE] },
  },
  {
    path: "enquadramentos",
    component: EnquadramentosComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "enquadramentos/cnpj/:cnpj",
    component: EnquadramentosComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "enquadramentos/:id",
    component: EnquadramentosComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "assinadores",
    component: AssinadoresComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "ciclos-vida",
    component: NovoCicloVidaComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "drei",
    component: DreiComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "documentos",
    component: DocumentosComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "comentarios",
    component: ComentariosComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "drei/cliente/:idCliente",
    component: DreiComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "drei/novo",
    component: CadastroDreiComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "credenciamento",
    component: CredenciamentoComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "credenciamento/cliente/:idCliente",
    component: CredenciamentoComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "credenciamento/novo",
    component: CadastroCredenciamentoComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "credenciamento/simulacao",
    component: SimulacaoCredenciamentoComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "credenciamento/impressao/:idCredenciamento",
    component: RelatorioCredenciamentoComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "proxy-usuario/:idCliente",
    component: LogarClienteComponent,
    canActivate: [AuthGuard],
    data: { roles: [TipoUsuario.ADMIN, TipoUsuario.USUARIO] },
  },
  {
    path: "blacklist/emissor",
    component: CVMBlackListComponent,
    canActivate: [AuthGuard],
    data: { roles: [TipoUsuario.ADMIN, TipoUsuario.USUARIO] },
  },
  {
    path: "blacklist/alto-risco",
    component: BaixoRiscoBlackListComponent,
    canActivate: [AuthGuard],
    data: { roles: [TipoUsuario.ADMIN, TipoUsuario.USUARIO] },
  },
  {
    path: "audesp-sp/:id",
    component: AUDESPSPComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
