<h1 class="title">Carteira de investimentos {{ complementoTitulo }}</h1>
<div style="display: flex;">
<span class="button-container" style="flex: 1; display: inline-block;">
  <button
    mat-flat-button
    color="primary"
    routerLink="retorno"
    style="margin-right: 0.5em; margin-bottom: 0.5em;"  >
    Relatório mensal
  </button>
  <button
    mat-flat-button
    color="primary"
    routerLink="retorno-acumulado"
    style="margin-right: 0.5em; margin-bottom: 0.5em;"
  >
    Relatório acumulado
  </button>
  <button
    mat-flat-button
    color="primary"
    routerLink="retorno-anual"
    style="margin-right: 0.5em; margin-bottom: 0.5em;"
  >
    Relatório anual
  </button>
  <button
    mat-flat-button
    color="primary"
    routerLink="visao-geral"
    style="margin-right: 0.5em; margin-bottom: 0.5em;"
  >
    Visão geral
  </button>
  <button
    mat-flat-button
    color="primary"
    routerLink="retornos-fundos"
    style="margin-right: 0.5em; margin-bottom: 0.5em;"
  >
    Retorno dos fundos acumulado
  </button>

  <!-- <button
    mat-flat-button
    color="primary"
    routerLink="retornos-fundos-financeiro"
    style="margin-right: 0.5em; margin-bottom: 0.5em;"
  >
    Retorno dos fundos acumulado (R$)
  </button>  -->

  <button
    mat-flat-button
    color="primary"
    routerLink="enquadramentos"
    style="margin-right: 0.5em; margin-bottom: 0.5em;"
  >
    Enquadramentos
  </button>

  <button
    mat-flat-button
    color="primary"
    target="_blank"
    (click)="openNew(['/', 'relatorio-dinamico', idCliente])"
    style="margin-right: 0.5em; margin-bottom: 0.5em;"
  >
    Relatório dinâmico
  </button>

  <button
    mat-flat-button
    color="primary"
    target="_blank"
    (click)="openNew(['/', 'lamina-resumo', idCliente])"
    style="margin-right: 0.5em; margin-bottom: 0.5em;"
  >
    Lâmina resumo
  </button>

  <button
    mat-flat-button
    color="primary"
    routerLink="riscos"
    style="margin-right: 0.5em; margin-bottom: 0.5em;"
  >
    Riscos
  </button>

  <button
    mat-flat-button
    color="primary"
    [routerLink]="['/', 'atribuicao-desempenho', idCliente]"
    style="margin-right: 0.5em; margin-bottom: 0.5em;"
  >
    Desempenho
  </button>

  <button
    mat-flat-button
    color="primary"
    [routerLink]="['/', 'indices-carteira', idCliente]"
    style="margin-right: 0.5em; margin-bottom: 0.5em;"
  >
    Índices
  </button>

  <button
    mat-flat-button
    color="primary"
    [routerLink]="['/', 'simulador-enquadramento', idCliente]"
    style="margin-right: 0.5em; margin-bottom: 0.5em;"
  >
    Simulador de enquadramento
  </button>

  <button
  mat-flat-button
  color="primary"
  [routerLink]="['/', 'ciclos-vida']"
  style="margin-right: 0.5em; margin-bottom: 0.5em;"
>
  Ciclo de Vida
</button>

<button
  mat-flat-button
  color="primary"
  [routerLink]="['/', 'drei','cliente', idCliente]"
  style="margin-right: 0.5em; margin-bottom: 0.5em;"
>
  DREI
</button>



<button
  mat-flat-button
  color="primary"
  [routerLink]="['/', 'credenciamento','cliente', idCliente]"
  style="margin-right: 0.5em; margin-bottom: 0.5em;"
>
  Credenciamento
</button>

<button
  mat-flat-button
  color="primary"
  [routerLink]="['/', 'compliance-anbima','cliente',idCliente]"
  style="margin-right: 0.5em; margin-bottom: 0.5em;"
>
  Compliance Anbima
</button>

<button
  mat-flat-button
  color="primary"
  [routerLink]="['/','audesp-sp',idCliente]"
  style="margin-right: 0.5em; margin-bottom: 0.5em;"
>
  Audesp
</button>



</span>


  <button
    mat-flat-button
    color="warn"
    style="margin-left: 30px; align-self: flex-start;"
    (click)="abrirDialogImportacao()"
  >
    <i class="fas fa-paperclip"></i>Importação de APRS
  </button>
</div>

<h4><i class="fas fa-search-dollar"></i>Fundos</h4>

<form id="form" [formGroup]="form" (ngSubmit)="adicionarFundo()">
  <div
    fxLayout="row"
    fxLayout.lt-md="column"
    fxLayoutGap="5px"
    fxLayoutGap.lt-md="0px"
    style="margin-bottom: 1em;"
  >
    <mat-form-field fxFlex="60">
      <input
        id="fundo"
        matInput
        type="text"
        #fundo
        placeholder="Fundo"
        [value]="filtroFundo | async"
        (keyup)="handleFiltro($event.target.value)"
        [matAutocomplete]="auto"
      />
    </mat-form-field>

    <mat-autocomplete
      #auto="matAutocomplete"
      [displayWith]="displayFundo"
      (optionSelected)="selectFundo($event.option.value)"
    >
      <mat-option *ngFor="let option of fundos$ | async" [value]="option"
        >{{ option.cnpj }} - {{ option.nome }}</mat-option
      >
    </mat-autocomplete>
    <mat-form-field fxFlex="30">
      <input
        matInput
        [matDatepicker]="picker"
        (dateChange)="atualizaValorCota()"
        formControlName="dataInvestimento"
        readonly
        placeholder="Data do investimento"
      />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <mat-form-field fxFlex="10">
      <input
        matInput
        mask="Hh:m0"
        [dropSpecialCharacters]="false"
        formControlName="hora"
        placeholder="Hora"
      />
    </mat-form-field>
  </div>
  <small *ngIf="showMessageNoQuote" style="color: red;"
    >Valor da cota para data selecionada não existe ou não foi
    encontrada.</small
  >

  <div
    fxLayout="row"
    fxLayout.lt-md="column"
    fxLayoutGap="5px"
    fxLayoutGap.lt-md="0px"
    style="margin-bottom: 1em;"
  >
    <mat-form-field fxFlex="40">
      <input
        #valor
        matInput
        currencyMask
        formControlName="valorInvestido"
        (keyup)="atualizaValorCota()"
        [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }"
        placeholder="Valor investido"
        required
        aria-label="Valor do investimento"
      />
      <mat-error *ngIf="!form.controls['valorInvestido'].valid"
        >Informe o valor do investimento</mat-error
      >
    </mat-form-field>

    <mat-form-field fxFlex="20">
      <input
        #valorCota
        matInput
        currencyMask
        formControlName="valorCota"
        readonly="true"
        [options]="{
          prefix: 'R$ ',
          thousands: '.',
          decimal: ',',
          precision: 8
        }"
        placeholder="Valor da cota na data"
        required
        aria-label="Valor da cota"
      />
      <mat-error *ngIf="!form.controls['valorCota'].valid"
        >Informe o valor da cota</mat-error
      >
    </mat-form-field>

    <mat-form-field fxFlex="40">
      <input
        style="text-align: right;"
        #cotas
        matInput
        formControlName="numCotas"
        placeholder="Número de cotas"
        required
        aria-label="Número de cotas"
      />
      <mat-error *ngIf="!form.controls['numCotas'].valid"
        >Informe o número de cotas</mat-error
      >
    </mat-form-field>
  </div>

  <h4>Informações adicionais</h4>
  <div
    fxLayout="row"
    fxLayout.lt-md="column"
    fxLayoutGap="5px"
    fxLayoutGap.lt-md="0px"
    style="margin-bottom: 1em;"
  >
    <mat-form-field fxFlex="20">
      <input
        matInput
        formControlName="codigoAlternativo"
        placeholder="Código alternativo"
        aria-label="Código alternativo "
      />
    </mat-form-field>
    <mat-form-field fxFlex="20">
      <input
        #valor
        matInput
        formControlName="banco"
        placeholder="Banco"
        aria-label="Banco"
      />
    </mat-form-field>

    <mat-form-field fxFlex="30">
      <input
        matInput
        formControlName="agencia"
        placeholder="Agência"
        aria-label="Agência"
      />
    </mat-form-field>
    <mat-form-field fxFlex="30">
      <input
        style="text-align: right;"
        matInput
        formControlName="conta"
        placeholder="Número da conta"
        aria-label="Número da conta"
      />
    </mat-form-field>
  </div>

  <div
    fxLayout="row"
    fxLayout.lt-md="column"
    fxLayoutGap="5px"
    fxLayoutGap.lt-md="0px"
    style="margin-bottom: 1em;"
  >
    <button
      type="submit"
      fxFlex="100"
      style="padding: 0.2em;"
      fxFlexAlign="center"
      fxFlexAlign.lt-md="stretch"
      matTooltip="Adicionar fundo ao conjunto"
      mat-flat-button
      [disabled]="form.invalid"
      color="primary"
    >
      <i class="fas fa-angle-double-down"></i>Incluir na carteira
    </button>
  </div>
</form>

<div fxLayout="row" style="margin: 15px 0 10px 0">
  <div fxFlex="35" fxFlex.lt-md="50"><strong>Número de fundos</strong></div>
  <div fxFlex="35" fxFlex.lt-md="50" style="text-align: right;">
    <strong>{{ conjunto.length || 0 }}</strong>
  </div>
</div>

<div fxLayout="row" style="margin: 10px 0 15px 0">
  <div fxFlex="35" fxFlex.lt-md="50"><strong>Saldo</strong></div>
  <div fxFlex="35" fxFlex.lt-md="50" style="text-align: right;">
    <strong>{{ saldo | currency: "BRL" }}</strong>
  </div>
</div>

<div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="15px">
  <mat-spinner [diameter]="45" *ngIf="!conjunto"></mat-spinner>
  <mat-list *ngIf="conjunto" class="fundos" fxFlex="70" fxFlex.lt-md="100">
    <mat-list-item
      *ngFor="let fundo of conjunto; let i = index"
      class="mat-elevation-z1"
    >
      <h3 matLine>
        <strong>{{ fundo.cnpjFundo }}</strong>
      </h3>
      <p matLine>{{ fundo.nomeFundo }}</p>
      <p *ngIf="fundo.codigoAlternativo" matLine>
        <strong>Código alternativo:</strong> {{ fundo.codigoAlternativo }}
      </p>
      <p *ngIf="fundo.banco" matLine>
        <strong>Banco:</strong> {{ fundo.banco }} <strong>Agência:</strong>
        {{ fundo.agencia }} <strong>Conta:</strong> {{ fundo.conta }}
      </p>
      <h4>{{ fundo.saldo | currency: "BRL" }}</h4>
      <button
        type="button"
        mat-icon-button
        color="primary"
        (click)="abrirInvestimento(fundo)"
        matTooltip="Visualizar investimento"
      >
        <i class="fas fa-search"></i>
      </button>
      <button
        type="button"
        mat-icon-button
        color="accent"
        (click)="abrirEdicaoDadosBancarios(fundo)"
        matTooltip="Alteração de dados bancários"
      >
        <i class="fas fa-edit"></i>
      </button>
      <button
        type="button"
        mat-icon-button
        color="warn"
        (click)="removerInvestimento(fundo)"
        matTooltip="Remover investimento"
      >
        <i class="fas fa-times"></i>
      </button>
    </mat-list-item>
  </mat-list>
</div>
<app-apr-pendentes [idCliente]="idCliente"></app-apr-pendentes>
