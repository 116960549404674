<div style="position: fixed; z-index: 9998; top: 0; left: 0; width: 100%; height: 100vh; display: flex; flex-direction: column; justify-content: center; align-items: center; background-color: rgba(255,255,255,0.8);" *ngIf="!loaded">  <mat-spinner diameter="40" style="margin: 0 auto;"></mat-spinner>
  <h4>Carregando...</h4>
</div>
<div style="display: flex; flex-flow: row;">
<form class="hidePrint" style="z-index: 9999; display: flex; width: 100%;">
  <mat-form-field style="z-index: 9999;">
    <input
      matInput
      [matDatepicker]="dp"
      [max]="atual"
      placeholder="Mês/Ano"
      [formControl]="date"
      [min]="minDate"
    />
    <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
    <mat-datepicker
      #dp
      startView="multi-year"
      (yearSelected)="chosenYearHandler($event)"
      (monthSelected)="chosenMonthHandler($event, dp)"
      panelClass="example-month-picker"
    >
    </mat-datepicker>
  </mat-form-field>
  <strong>
    Data do último saldo: {{ultimaDataSaldo | date:'dd/MM/yyyy'}}
  </strong>
  <span style="flex: 1"></span>
  <button mat-flat-button (click)="imprimir()" color="primary" style="flex-grow: 0; align-self: center; justify-self: center;">
    <i class="fas fa-print"></i> Versão para impressão
  </button>
</form>

</div>
<div id="report">
  <div class="page" style="justify-content: center; align-self: center;">
    <img style="justify-self: center; align-self: center; width: auto; max-height: 250px;" [src]="'data:image/png;base64,'+cliente?.foto" />
    <br/>
    <h1 style="text-align: center;">{{cliente?.nome?.toUpperCase()}}</h1>
  </div>
  <div class="page">
    <h2>Visão Geral da carteira</h2>
    <app-resumo-carteira [relatorioDinamico]="true" (loadingStatus)="updateLoading(0, $event)" (getFundos)="setCnpjFundos($event)" [dataEntrada]="data.format('DD/MM/YYYY')" ></app-resumo-carteira>
  </div>
  <div class="page">
    <h2>Enquadramentos</h2>
    <app-enquadramentos [relatorioDinamico]="true" (loadingStatus)="updateLoading(1, $event)" [dataEntrada]="data.format('DD/MM/YYYY')" ></app-enquadramentos>
  </div>
  <div class="page">
    <h2>Retornos do mês</h2>
    <br/>
    <app-relatorio-retornos [relatorioDinamico]="true" (loadingStatus)="updateLoading(2, $event)" [dataEntrada]="data.format('DD/MM/YYYY')" ></app-relatorio-retornos>
  </div>

  <div class="page">
    <h2>Retornos acumulados</h2>
    <br/>
    <app-relatorio-retornos-acumulado [relatorioDinamico]="true" (loadingStatus)="updateLoading(3, $event)" [dataEntrada]="data.format('DD/MM/YYYY')"></app-relatorio-retornos-acumulado>
  </div>

  <div class="page">
    <h2>Gráfico - retorno acumulado no ano</h2>
    <br/>
    <span style="width: 100%; justify-content: center; align-content: center; align-items: center;" >
      <app-grafico-retorno-acumulado [relatorioDinamico]="true" (loadingStatus)="updateLoading(4, $event)" [dataEntrada]="data.format('DD/MM/YYYY')"></app-grafico-retorno-acumulado>
    </span>
  </div>

  <div class="page">
    <h2>Gráfico - Indices econômicos</h2>
    <br/>
      <app-grafico-meta [meta]="selic" [relatorioDinamico]="true" (loadingStatus)="updateLoading(5, $event)"  [dataEntrada]="data.format('DD/MM/YYYY')"></app-grafico-meta>
  </div>
  <div class="page">
    <h2>Gráfico - Indices econômicos</h2>
    <br/>
      <app-grafico-meta [meta]="ipca" [relatorioDinamico]="true" (loadingStatus)="updateLoading(6, $event)"  [dataEntrada]="data.format('DD/MM/YYYY')"></app-grafico-meta>
  </div>
  <div class="page">
    <h2>Gráfico - Indices econômicos</h2>
    <br/>
      <app-grafico-meta [meta]="inpc" [relatorioDinamico]="true" (loadingStatus)="updateLoading(7, $event)"  [dataEntrada]="data.format('DD/MM/YYYY')"></app-grafico-meta>
  </div>
  <div class="page">
    <h2>Medidas de risco</h2>
    <br/>
    <app-var-fundos-carteira [investimentos]="investimentos" (loadingStatus)="updateLoading(8, $event)" [dataEntrada]="data.format('DD/MM/YYYY')"></app-var-fundos-carteira>
  </div>

  <div class="page">
    <h2>Risco de liquidez</h2>
    <app-resumo-carteira [relatorioDinamico]="true" (loadingStatus)="updateLoading(9, $event)"  [columnsToAdd]="['resgate']" [graficoLiquidez]="true" [dataEntrada]="data.format('DD/MM/YYYY')" ></app-resumo-carteira>
    <br/>
    <br/>
    <!-- <div style="text-align: center;">
      <img src="/assets/imagens/assinatura.png" style="width: 300px; border-bottom: 2px solid #000;" />
      <strong style="display: block;">Assinatura</strong>
    </div> -->
  </div>

</div>
