import { DragDropModule } from "@angular/cdk/drag-drop";
import { registerLocaleData } from "@angular/common";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import localePt from "@angular/common/locales/pt";
import { LOCALE_ID, NgModule } from "@angular/core";
import { FlexLayoutModule, MediaObserver } from "@angular/flex-layout";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { MatIconModule } from "@angular/material/icon";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatTableModule } from "@angular/material/table";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { StoreModule } from "@ngrx/store";
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { ChartsModule } from "ng2-charts";
import { CurrencyMaskModule } from "ng2-currency-mask";
import { NgxFileDropModule } from "ngx-file-drop";
import { NgxMaskModule } from "ngx-mask";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { FundoDirective } from "./directives/fundo.directive";
import { MaterialModule } from "./material.module";
import { metaReducers, reducers } from "./reducers";
import { APRComponent } from "./_components/APR/APR.component";
import { CadastroAPRComponent } from "./_components/APR/CadastroAPR/CadastroAPR.component";
import { DialogCadastroAprComponent } from "./_components/APR/CadastroAPR/dialog-cadastro-apr/dialog-cadastro-apr.component";
import { DialogSelecaoConta } from "./_components/APR/CadastroAPR/dialog-selecao-conta/dialog-selecao-conta.component";
import { DialogDescricaoAPRComponent } from "./_components/APR/DialogDescricaoAPR/DialogDescricaoAPR.component";
import { AUDESPSPComponent } from "./_components/AUDESPSP/audespsp.component";
import { DialogCadastroRIPPComponent } from "./_components/AUDESPSP/dialog-cadastro-ripp/dialog-cadastro-ripp.component";
import { DialogEditarRIPPComponent } from "./_components/AUDESPSP/dialog-editar-ripp/dialog-editar-ripp.component";
import { DialogRIPPComponent } from "./_components/AUDESPSP/dialog-ripp/dialog-ripp.component";
import { BaixoRiscoBlackListComponent } from "./_components/BaixoRiscoBlackList/baixo-risco-blacklist.component";
import { DialogCadBaixoRiscoBlackList } from "./_components/BaixoRiscoBlackList/CadEmissorBlackListBaixoRisco/dialog-cad-baixo-risco-blacklist.component";
import { BenchmarkComponent } from "./_components/benchmark/benchmark.component";
import { CadastroBenchmarkComponent } from "./_components/benchmark/cadastro-benchmark/cadastro-benchmark.component";
import { NovoCicloVidaComponent } from "./_components/ciclo-vida/simular-ciclo-vida/simular-ciclo-vida.component";
import { AcoesClienteComponent } from "./_components/clientes/acoes-cliente/acoes-cliente.component";
import { AprPendentesComponent } from "./_components/clientes/apr-pendentes/apr-pendentes.component";
import { AssinadoresComponent } from "./_components/clientes/assinadores/Assinadores.component";
import { AutocompleteAssinadoresComponent } from "./_components/clientes/assinadores/autocomplete-assinadores/autocomplete-assinadores.component";
import { AutocompleteClientesComponent } from "./_components/clientes/autocomplete-clientes/autocomplete-clientes.component";
import { CadastroClienteComponent } from "./_components/clientes/cadastro-cliente/cadastro-cliente.component";
import { AtribuicaoDesempenhoComponent } from "./_components/clientes/carteira/atribuicao-desempenho/atribuicao-desempenho.component";
import { CarteiraComponent } from "./_components/clientes/carteira/carteira.component";
import { DialogAjusteSaldoComponent } from "./_components/clientes/carteira/dialog-ajuste-saldo/dialog-ajuste-saldo.component";
import { DialogAlteracaoDadosComponent } from "./_components/clientes/carteira/dialog-alteracao-dados/dialog-alteracao-dados.component";
import { EnquadramentosComponent } from "./_components/clientes/carteira/enquadramentos/enquadramentos.component";
import { GraficoRetornoAcumuladoComponent } from "./_components/clientes/carteira/grafico-retorno-acumulado/grafico-retorno-acumulado.component";
import { RelatorioRetornosAcumuladoComponent } from "./_components/clientes/carteira/relatorio-retornos-acumulado/relatorio-retornos-acumulado.component";
import { RelatorioRetornosAnualComponent } from "./_components/clientes/carteira/relatorio-retornos-anual/relatorio-retornos-anual.component";
import { RelatorioRetornosFundosAcumuladoComponent } from "./_components/clientes/carteira/relatorio-retornos-fundos-acumulado/relatorio-retornos-fundos-acumulado.component";
import { RelatorioRetornosFundosFinanceiroAcumuladoComponent } from "./_components/clientes/carteira/relatorio-retornos-fundos-financeiro-acumulado/relatorio-retornos-fundos-financeiro-acumulado.component";
import { RelatorioRetornosComponent } from "./_components/clientes/carteira/relatorio-retornos/relatorio-retornos.component";
import { RiscosComponent } from "./_components/clientes/carteira/riscos/riscos.component";
import { VisualizarInvestimentoComponent } from "./_components/clientes/carteira/visualizar-investimento/visualizar-investimento.component";
import { ClientesComponent } from "./_components/clientes/clientes.component";
import { DialogImportacaoExtrato } from "./_components/clientes/dialog-importacao-extrato/dialog-importacao-extrato.component";
import { ComentariosComponent } from "./_components/Comentarios/Comentarios.component";
import { CriarComentarioComponent } from "./_components/Comentarios/criar-comentario/criar-comentario.component";
import { ComplianceAnbimaComponent } from "./_components/compliance-anbima/compliance-anbima.component";
import { FundoComplianceComponent } from "./_components/compliance-anbima/FundoCompliance/FundoCompliance.component";
import { ConjuntosComponent } from "./_components/conjuntos/conjuntos.component";
import { NovoConjuntoComponent } from "./_components/conjuntos/novo-conjunto/novo-conjunto.component";
import { CadastroCredenciamentoComponent } from "./_components/credenciamento/cadastro-credenciamentro/cadastro-credenciamento.component";
import { CredenciamentoComponent } from "./_components/credenciamento/credenciamento.component";
import { RelatorioCredenciamentoComponent } from "./_components/credenciamento/relatorio-credenciamento/relatorio-credenciamento.component";
import { SimulacaoCredenciamentoComponent } from "./_components/credenciamento/simulacao-credenciamento/simulacao-credenciamento.component";
import { DialogCadCVMBlackList } from "./_components/CVMBlackList/CadEmissorBlackListCVM/dialog-cad-cvm-blacklist.component";
import { CVMBlackListComponent } from "./_components/CVMBlackList/cvm-blacklist.component";
import { CriarDocumentoComponent } from "./_components/Documentos/criar-documento/criar-documento.component";
import { DocumentosComponent } from "./_components/Documentos/Documentos.component";
import { CadastroDreiComponent } from "./_components/DREI/cadastro-drei/cadastro-drei.component";
import { DreiComponent } from "./_components/DREI/drei.component";
import { AlteracaoLoteComponent } from "./_components/fundo-investimento/alteracao-lote/alteracao-lote.component";
import { AutocompleteFundoInvestimentosComponent } from "./_components/fundo-investimento/autocomplete-fundo-investimentos/autocomplete-fundo-investimentos.component";
import { CadastroFundoInvestimentoComponent } from "./_components/fundo-investimento/cadastro-fundo-investimento/cadastro-fundo-investimento.component";
import { FundoInvestimentoComponent } from "./_components/fundo-investimento/fundo-investimento.component";
import { IndicesFundosCarteiraComponent } from "./_components/fundo-investimento/indices-fundos-carteira/indices-fundos-carteira.component";
import { InformesComponent } from "./_components/fundo-investimento/informes/informes.component";
import { RelatorioRentabilidadeFundoComponent } from "./_components/fundo-investimento/relatorio-rentabilidade-fundo/relatorio-rentabilidade-fundo.component";
import { VarFundosCarteiraComponent } from "./_components/fundo-investimento/var-fundos-carteira/var-fundos-carteira.component";
import { InicioComponent } from "./_components/inicio/inicio.component";
import { LogsComponent } from "./_components/inicio/logs/logs.component";
import { ResumoCarteiraComponent } from "./_components/inicio/resumo-carteira/resumo-carteira.component";
import { LaminaResumoComponent } from "./_components/lamina-resumo/lamina-resumo.component";
import { LoaderComponent } from "./_components/Loader/loader.component";
import { LogarClienteComponent } from "./_components/logar-cliente/logar-cliente.component";
import { LoginComponent } from "./_components/login/login.component";
import { RecuperarSenhaComponent } from "./_components/login/recuperar-senha/recuperar-senha.component";
import { CadastroMetasComponent } from "./_components/metas/cadastro-metas/cadastro-metas.component";
import { DialogGraficoMetaComponent } from "./_components/metas/dialog-grafico-meta/dialog-grafico-meta.component";
import { GraficoMetaComponent } from "./_components/metas/grafico-meta/grafico-meta.component";
import { MetasComponent } from "./_components/metas/metas.component";
import { TelaGraficosMetaComponent } from "./_components/metas/tela-graficos-meta/tela-graficos-meta.component";
import { DialogAdicionarOtimizadorComponent } from "./_components/otimizador/dialog-adicionar-otimizador/dialog-adicionar-otimizador.component";
import { OtimizadorComponent } from "./_components/otimizador/otimizador.component";
import { RankingComponent } from "./_components/otimizador/ranking/ranking.component";
import { ResultadoOtimizadorComponent } from "./_components/otimizador/resultado-otimizador/resultado-otimizador.component";
import { PainelComponent } from "./_components/painel/painel.component";
import { PerfilComponent } from "./_components/painel/perfil/perfil.component";
import { RecuperacaoSenhaComponent } from "./_components/recuperacao-senha/recuperacao-senha.component";
import { RelatorioDinamicoClienteComponent } from "./_components/relatorio-dinamico-cliente/relatorio-dinamico-cliente.component";
import { AdicionarEnquadramentoFundoComponent } from "./_components/resolucao/adicionar-enquadramento-fundo/adicionar-enquadramento-fundo.component";
import { AutocompleteEnquadramentoComponent } from "./_components/resolucao/autocomplete-enquadramento/autocomplete-enquadramento.component";
import { CadastroResolucaoComponent } from "./_components/resolucao/cadastro-resolucao/cadastro-resolucao.component";
import { ResolucaoComponent } from "./_components/resolucao/resolucao.component";
import { DialogAdicionarFundoComponent } from "./_components/simulador-enquadramento/dialog-adicionar-fundo/dialog-adicionar-fundo.component";
import { DialogMovimentoSimuladorEnquadramentoComponent } from "./_components/simulador-enquadramento/dialog-movimento-simulador-enquadramento/dialog-movimento-simulador-enquadramento.component";
import { DialogMovimentosSimuladorComponent } from "./_components/simulador-enquadramento/dialog-movimentos-simulador/dialog-movimentos-simulador.component";
import { SimuladorEnquadramentoComponent } from "./_components/simulador-enquadramento/simulador-enquadramento.component";
import { TermosComponent } from "./_components/termos/termos.component";
import { UploadArquivoComponent } from "./_components/upload-arquivo/upload-arquivo.component";
import { CadastroUsuarioComponent } from "./_components/usuarios/cadastro-usuario/cadastro-usuario.component";
import { UsuariosComponent } from "./_components/usuarios/usuarios.component";
import { HasAcaoDirective } from "./_directives/has-acao.directive";
import { HasRoleDirective } from "./_directives/has-role.directive";
import { AuthGuard } from "./_guards/auth-guard.service";
import { DialogConfirmComponent } from "./_helpers/dialog-confirm/dialog-confirm.component";
import { DialogConfirmacaoComponent } from "./_helpers/dialog-confirmacao/dialog-confirmacao-component";
import { DialogDeleteComponent } from "./_helpers/dialog-delete/dialog-delete.component";
import { DialogLoadingComponent } from "./_helpers/dialog-loading/dialog-loading.component";
import { getDutchPaginatorIntl } from "./_helpers/translate-table";
import { UIService } from "./_helpers/ui.service";
import { AssetPipe } from "./_pipes/Asset.pipe";
import { CpfCnpjPipe } from "./_pipes/cpfcnpj.pipe";
import { GrauRisco } from "./_pipes/grauRisco.pipe";
import { GroupByPipe } from "./_pipes/groupBy.pipe";
import { ISINPipe } from "./_pipes/ISIN.pipe";
import { MediaPipe } from "./_pipes/media.pipe";
import { MovimentoCarteiraPipe } from "./_pipes/movimento-carteira.pipe";
import { ResumoNomeFundoPipe } from "./_pipes/resumoNomeFundo.pipe";
import { SituacaoEnquadramentoPipe } from "./_pipes/situacaoEnquadramento.pipe";
import { SortPipe } from "./_pipes/sort.pipe";
import { SortReversePipe } from "./_pipes/sortReverse.pipe";
import { SumPipe } from "./_pipes/sum.pipe";
import { TipoUsuarioPipe } from "./_pipes/tipo-usuario.pipe";
import { AnbimaService } from "./_services/anbima.service";
import { APRService } from "./_services/apr.service";
import { AuthInterceptor } from "./_services/auth-interceptor.service";
import { AuthService } from "./_services/auth.service";
import { BenchmarkService } from "./_services/benchmark.service";
import { CarteiraService } from "./_services/carteira.service";
import { ConjuntoService } from "./_services/conjunto.service";
import { DocumentoService } from "./_services/documento.service";
import { IndiceService } from "./_services/indices.service";
import { MetaService } from "./_services/meta.service";
import { OtimizadorService } from "./_services/otimizador.service";
import { ResolucaoService } from "./_services/resolucao.service";
import { UserValidator } from "./_validators/user.validator";

registerLocaleData(localePt);

@NgModule({
  declarations: [
    AppComponent,
    PainelComponent,
    InicioComponent,
    LoginComponent,
    BenchmarkComponent,
    CadastroBenchmarkComponent,
    FundoInvestimentoComponent,
    UsuariosComponent,
    DialogConfirmComponent,
    DialogDeleteComponent,
    CadastroFundoInvestimentoComponent,
    ClientesComponent,
    CadastroClienteComponent,
    CarteiraComponent,
    CadastroUsuarioComponent,
    OtimizadorComponent,
    DialogAdicionarOtimizadorComponent,
    ResultadoOtimizadorComponent,
    FundoDirective,
    ConjuntosComponent,
    NovoConjuntoComponent,
    RankingComponent,
    UploadArquivoComponent,
    DialogLoadingComponent,
    TermosComponent,
    TipoUsuarioPipe,
    MediaPipe,
    SortPipe,
    CpfCnpjPipe,
    SortReversePipe,
    MovimentoCarteiraPipe,
    HasRoleDirective,
    HasAcaoDirective,
    PerfilComponent,
    InformesComponent,
    VisualizarInvestimentoComponent,
    RecuperarSenhaComponent,
    RecuperacaoSenhaComponent,
    SumPipe,
    GrauRisco,
    GroupByPipe,
    SituacaoEnquadramentoPipe,
    ResumoNomeFundoPipe,
    ISINPipe,
    AssetPipe,
    ResumoCarteiraComponent,
    MetasComponent,
    CadastroMetasComponent,
    RelatorioRetornosComponent,
    LogsComponent,
    RelatorioRetornosAcumuladoComponent,
    AlteracaoLoteComponent,
    DialogAlteracaoDadosComponent,
    APRComponent,
    CadastroAPRComponent,
    AutocompleteFundoInvestimentosComponent,
    AssinadoresComponent,
    AutocompleteClientesComponent,
    AutocompleteAssinadoresComponent,
    DialogCadastroAprComponent,
    AprPendentesComponent,
    DialogConfirmacaoComponent,
    ResolucaoComponent,
    CadastroResolucaoComponent,
    EnquadramentosComponent,
    AutocompleteEnquadramentoComponent,
    AdicionarEnquadramentoFundoComponent,
    RelatorioRetornosAnualComponent,
    RelatorioRentabilidadeFundoComponent,
    AcoesClienteComponent,
    DialogImportacaoExtrato,
    DialogSelecaoConta,
    DialogDescricaoAPRComponent,
    DialogGraficoMetaComponent,
    TelaGraficosMetaComponent,
    GraficoMetaComponent,
    RelatorioDinamicoClienteComponent,
    GraficoRetornoAcumuladoComponent,
    VarFundosCarteiraComponent,
    LaminaResumoComponent,
    RiscosComponent,
    IndicesFundosCarteiraComponent,
    AtribuicaoDesempenhoComponent,
    SimuladorEnquadramentoComponent,
    DialogAdicionarFundoComponent,
    DialogMovimentoSimuladorEnquadramentoComponent,
    NovoCicloVidaComponent,
    ComplianceAnbimaComponent,
    FundoComplianceComponent,
    DreiComponent,
    CadastroDreiComponent,
    CredenciamentoComponent,
    CadastroCredenciamentoComponent,
    RelatorioCredenciamentoComponent,
    DialogMovimentosSimuladorComponent,
    DocumentosComponent,
    CriarDocumentoComponent,
    CriarComentarioComponent,
    ComentariosComponent,
    SimulacaoCredenciamentoComponent,
    RelatorioRetornosFundosAcumuladoComponent,
    DialogAjusteSaldoComponent,
    LogarClienteComponent,
    CVMBlackListComponent,
    BaixoRiscoBlackListComponent,
    LoaderComponent,
    AUDESPSPComponent,
    RelatorioRetornosFundosFinanceiroAcumuladoComponent,
    DialogRIPPComponent,
    DialogCadastroRIPPComponent,
    DialogEditarRIPPComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    MatTableModule,
    FlexLayoutModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxMaskModule.forRoot(),
    FormsModule,
    CurrencyMaskModule,
    MatIconModule,
    DragDropModule,
    NgxChartsModule,
    NgxFileDropModule,
    MatProgressSpinnerModule,
    ChartsModule,
    StoreModule.forRoot(reducers, { metaReducers }),
  ],
  providers: [
    MediaObserver,
    BenchmarkService,
    UIService,
    AuthService,
    AuthInterceptor,
    IndiceService,
    AuthGuard,
    OtimizadorService,
    ConjuntoService,
    CarteiraService,
    UserValidator,
    MetaService,
    APRService,
    ResolucaoService,
    AnbimaService,
    DocumentoService,
    { provide: MAT_DATE_LOCALE, useValue: "pt-BR" },
    { provide: LOCALE_ID, useValue: "pt-BR" },
    { provide: MatPaginatorIntl, useValue: getDutchPaginatorIntl() },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    DialogConfirmComponent,
    DialogDeleteComponent,
    DialogAdicionarOtimizadorComponent,
    DialogLoadingComponent,
    TermosComponent,
    VisualizarInvestimentoComponent,
    RecuperarSenhaComponent,
    DialogAlteracaoDadosComponent,
    DialogCadastroAprComponent,
    DialogConfirmacaoComponent,
    AdicionarEnquadramentoFundoComponent,
    DialogImportacaoExtrato,
    DialogSelecaoConta,
    DialogDescricaoAPRComponent,
    DialogGraficoMetaComponent,
    DialogAdicionarFundoComponent,
    DialogMovimentoSimuladorEnquadramentoComponent,
    DialogMovimentosSimuladorComponent,
    CriarDocumentoComponent,
    CriarComentarioComponent,
    DialogAjusteSaldoComponent,
    DialogCadCVMBlackList,
    DialogCadBaixoRiscoBlackList,
    DialogRIPPComponent,
    DialogEditarRIPPComponent,
    LoaderComponent,
    DialogCadastroRIPPComponent,
  ],
})
export class AppModule {}
