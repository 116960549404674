import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DataSource } from '@angular/cdk/table';
import { CollectionViewer } from '@angular/cdk/collections';
import { catchError, finalize, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Page, PageRequest } from '../_models/PaginatedResult';
import { Cliente } from '../_models/cliente.model';
import { Conjunto } from '../_models/conjuntos.model';

export interface ClienteQuery{
  search: string
}

@Injectable({
  providedIn: 'root'
})
export class ClienteService {

  constructor(private http: HttpClient) { }

  getEnderecoPorCep(cep: string) {
    const url = `${environment.PATH_CONSULTA_CEP}/${cep}/json`;
    return this.http.get(url);
  }

  salvarCliente(obj: Cliente) {
    const url = `${environment.PATH_API}/cliente`;
    return this.http.post(url, obj);
  }

  atualizarCliente(idCliente: number, obj: Cliente) {
    const url = `${environment.PATH_API}/cliente/${idCliente}`;
    return this.http.put(url, obj);
  }

  listarCliente(nome?: string) {
    const url = `${environment.PATH_API}/cliente`;
    let params = new HttpParams();
    if (nome) {
      params = params.append('nome', nome);
    }
    params = params.append('page', '0');
    params = params.append('limit', '50');
    return this.http.get<Page<Cliente>>(url, {params: params}).pipe(
      map(c => c.data)
    );
  }

  listarClientePaginado(request: PageRequest<Cliente>, query: ClienteQuery):Observable<Page<Cliente>> {
    const url = `${environment.PATH_API}/cliente`;
    let params = new HttpParams();
    if (query.search) {
      params = params.append('nome', query.search);
    }
    params = params.append('page', request.page.toString());
    params = params.append('limit', request.size.toString());
    return this.http.get<Page<Cliente>>(url, {params: params});
  }

  buscarClientePorId(idCliente) {
    const url = `${environment.PATH_API}/cliente/${idCliente}`;
    return this.http.get<Cliente>(url);
  }

  buscarConjuntosCliente(idCliente: number) {
    const url = `${environment.PATH_API}/cliente/${idCliente}/conjuntos`;
    return this.http.get<Conjunto[]>(url);
  }

  apagarCliente(idCliente) {
    const url = `${environment.PATH_API}/cliente/${idCliente}`;
    return this.http.delete(url);
  }

  retornaLogoCliente(idCliente?: number, email?: string) {
    let url = `${environment.PATH_API}/cliente/logo`;
    let params = new HttpParams();
    if (idCliente) {
      url += `/${idCliente}`;
    } else {
      params = params.append('email', email);
    }
    return this.http.get(url, {params: params});
  }
}

