<h1>Benchmark {{benchmark ? benchmark.nome : formBenchmark.get('nome').value}}</h1>

<form  [formGroup]="formBenchmark" style="display: block;">
    <mat-form-field *ngIf="!idBenchmark">
        <input #nome matInput formControlName="nome" placeholder="Nome do benchmark" required aria-label="Nome">
        <mat-error *ngIf="!nome.valid">Informe nome</mat-error>
    </mat-form-field>
    <span class="fill-space"></span>
    <button type="button" style="float: right; margin-bottom: 1em;" (click)="salvar()" mat-raised-button color="warn"><mat-icon>save</mat-icon> Salvar dados</button>
</form>

<form [formGroup]="form" style="display: block; clear: both;" (ngSubmit)="adicionarQuota()">
    <mat-form-field>
        <input matInput [matDatepicker]="picker" readonly required formControlName="data" placeholder="Selecione a data">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <mat-form-field>
        <!-- <input #percentual12 matInput formControlName="percentual12" required [dropSpecialCharacters]="false" placeholder="Percentual 12M" aria-label="Percentual 12" mask="percent" sufix="%"> -->
        <input #percentual12 matInput formControlName="percentual12" required [dropSpecialCharacters]="false" placeholder="Percentual 12M" aria-label="Percentual 12" [patterns]="customPatterns" mask="0*.0000" sufix="%">
        <mat-error *ngIf="!percentual12.valid">Informe o valor Percentual 12</mat-error>
    </mat-form-field>
    <mat-form-field>
        <!-- <input #percentual24 matInput formControlName="percentual24" required [dropSpecialCharacters]="false" placeholder="Percentual 24M" aria-label="Percentual 24" mask="percent" sufix="%"> -->
        <input #percentual24 matInput formControlName="percentual24" required [dropSpecialCharacters]="false" placeholder="Percentual 24M" aria-label="Percentual 24" [patterns]="customPatterns" mask="0*.0000" sufix="%">
        <mat-error *ngIf="!percentual24.valid">Informe o valor Percentual 24</mat-error>
    </mat-form-field>
    <mat-form-field>
        <input #percentualAno matInput formControlName="percentualAno" required placeholder="Percentual anual" [dropSpecialCharacters]="false" aria-label="Percentual Ano" [patterns]="customPatterns" mask="0*.0000" sufix="%">
        <!-- <input #percentualAno matInput formControlName="percentualAno" required placeholder="Percentual anual" [dropSpecialCharacters]="false" aria-label="Percentual Ano" mask="percent" sufix="%"> -->
        <mat-error *ngIf="!percentualAno.valid">Informe o valor Percentual anual</mat-error>
    </mat-form-field>
    <button type="submit" [disabled]="!form.valid" mat-raised-button color="accent"><mat-icon>playlist_add</mat-icon> Adicionar Quota </button>
    
    <table #table *ngIf="quotes" mat-table [dataSource]="quotesDatasource">
        <tr mat-header-row *matHeaderRowDef="columnsToDisplay;sticky:true"></tr>
        <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></tr>
        <ng-container matColumnDef="data">
            <th mat-header-cell *matHeaderCellDef> Data </th>
            <td mat-cell *matCellDef="let quote"> {{quote.data | date:'dd/MM/yyyy'}} </td>
        </ng-container>
        <ng-container matColumnDef="percentual12">
            <th mat-header-cell *matHeaderCellDef> Percentual 12M </th>
            <td mat-cell *matCellDef="let quote"> {{(quote.perc12 / 100) | percent:'1.4-4'}} </td>
        </ng-container>
        <ng-container matColumnDef="percentual24">
            <th mat-header-cell *matHeaderCellDef> Percentual 24M </th>
            <td mat-cell *matCellDef="let quote"> {{(quote.perc24 / 100) | percent:'1.4-4'}}</td>
        </ng-container>
        <ng-container matColumnDef="percentualAno">
            <th mat-header-cell *matHeaderCellDef> Percentual Ano </th>
            <td mat-cell *matCellDef="let quote"> {{(quote.percAno / 100) | percent:'1.4-4'}}</td>
        </ng-container>

        <ng-container matColumnDef="remover">
            <th mat-header-cell *matHeaderCellDef style="text-align: right"> Remover </th>
            <td mat-cell *matCellDef="let quote; let i = index" style="text-align: center">
                <button color="warn" mat-icon-button (click)="removerQuota(i)"><i class="fas fa-times"></i></button>
            </td>
        </ng-container>
    </table>
</form>