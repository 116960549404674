import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Comentario, Documento } from '../_models/documentos.model';
import { Drei } from '../_models/drei.mode';
import { Page, PageRequest } from '../_models/PaginatedResult';

export interface DocumentoQuery{
  search: string
}

@Injectable({
  providedIn: 'root'
})
export class ComentarioService {

  constructor(private http: HttpClient) { }

  listarDocumentosPaginado(request: PageRequest<Comentario>, query: DocumentoQuery,id?:string):Observable<Page<Comentario>> {
    const url = `${environment.PATH_API}/comentario`;
    let params = new HttpParams();
    if (query.search) {
      params = params.append('titulo', query.search);
    }
    params = params.append('page', request.page.toString());
    params = params.append('limit', request.size.toString());
    return this.http.get<Page<Comentario>>(url, {params: params});
  }

  criarDocumento(documento:any){
    const url = `${environment.PATH_API}/comentario`;
    return this.http.post(url, documento);
  }

  buscarLinkDocumento(idDocumento:any){
    const url = `${environment.PATH_API}/comentario/link/${idDocumento}`;
    return this.http.get(url);
  }

  deleteDocumento(idDocumento: any){
    const url = `${environment.PATH_API}/comentario/${idDocumento}`;
    return this.http.delete(url);
  }


}

