// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // PATH_API: "http://localhost:5010/api",
  PATH_API: "https://www.liasys.net/api",
  PATH_CONSULTA_CEP: "https://viacep.com.br/ws/",
  LIMITE_REGISTROS_LISTA: 15,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.

 */
import "zone.js/dist/zone-error"; // Included with Angular CLI.
