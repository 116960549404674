import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormControl } from "@angular/forms";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { MatDatepicker } from "@angular/material/datepicker";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from "moment";
import { Moment } from "moment";
import { UIService } from "src/app/_helpers/ui.service";
import { AudespRipp } from "src/app/_models/audesp.model";
import { FundoAudesp } from "src/app/_models/fundos-audesp.model";
import { AUDESPService } from "src/app/_services/audesp.service";
import { AuthService, TipoUsuario } from "src/app/_services/auth.service";
import { CarteiraService } from "src/app/_services/carteira.service";
import { MY_FORMATS } from "../clientes/carteira/relatorio-retornos/relatorio-retornos.component";
import { DialogCadastroRIPPComponent } from "./dialog-cadastro-ripp/dialog-cadastro-ripp.component";
import { DialogEditarRIPPComponent } from "./dialog-editar-ripp/dialog-editar-ripp.component";
import { DialogRIPPComponent } from "./dialog-ripp/dialog-ripp.component";

@Component({
  selector: "app-audespsp",
  templateUrl: "./audespsp.component.html",
  styleUrls: ["./audespsp.component.scss"],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class AUDESPSPComponent implements OnInit {
  tipoUsuario = TipoUsuario;
  idCliente: number;
  conjunto: FundoAudesp[];
  ripp: AudespRipp;
  ripps: AudespRipp[];
  date = new FormControl(moment());
  atual = moment();
  allowExport: number;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private uiService: UIService,
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private carteiraService: CarteiraService,
    private audespService: AUDESPService
  ) {
    this.idCliente = route.snapshot.params["id"];
  }
  columns = ["Linha", "Código", "Fundo", "Segmento", "Situação"];

  ngOnInit() {
    this.getCarteiraCliente();
  }

  getCarteiraCliente = () => {
    this.carteiraService
      .getCarteiraAudespCliente(
        this.idCliente,
        this.date.value.format("YYYYMM")
      )
      .subscribe((dados) => {
        this.conjunto = dados;

        this.allowExport = dados.filter((r) => r.ripp > 0).length;
      });
  };

  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue.startOf("month"));
    this.getCarteiraCliente();
  }

  chosenMonthHandler(
    normalizedMonth: Moment,
    datepicker: MatDatepicker<Moment>
  ) {
    const ctrlValue = this.date.value;
    ctrlValue.month(normalizedMonth.month());
    this.date.setValue(ctrlValue.startOf("month"));
    if (datepicker) {
      datepicker.close();
    }
    this.getCarteiraCliente();
  }

  abrirRIPP(investimento: FundoAudesp) {
    const dialog = this.dialog.open(DialogRIPPComponent, {
      data: {
        idCliente: this.idCliente,
        cnpj: investimento.cnpj,
        id: investimento.id,
        periodo: this.date.value,
      },
      width: "50vw",
    });

    dialog.beforeClosed().subscribe((v) => {
      this.getCarteiraCliente();
    });
  }

  editarRIPP(investimento: FundoAudesp) {
    const dialog = this.dialog.open(DialogEditarRIPPComponent, {
      data: {
        id: investimento.status,
      },
      width: "50vw",
    });

    dialog.beforeClosed().subscribe((v) => {
      this.getCarteiraCliente();
    });
  }

  abrirCadastro(investimento: FundoAudesp) {
    const dialog = this.dialog.open(DialogCadastroRIPPComponent, {
      data: {
        idCliente: this.idCliente,
        cnpj: investimento.cnpj,
        id: investimento.id,
        periodo: this.date.value,
      },
      width: "50vw",
    });

    dialog.beforeClosed().subscribe((v) => {
      this.getCarteiraCliente();
    });
  }

  exportar_cadastros() {
    this.audespService
      .exportarXMLCadastro(this.date.value, this.idCliente)
      .subscribe((xml) => {
        var element = document.createElement("a");
        var blob = new Blob([xml.xml], {
          type: "text/xml",
        });
        var url = URL.createObjectURL(blob);
        element.href = url;
        element.setAttribute("download", "cadastro_fundos.xml");
        document.body.appendChild(element);
        element.click();
      });
  }

  exportar_fundos() {
    this.audespService
      .exportarXMLFundo(this.date.value, this.idCliente)
      .subscribe((xml) => {
        var element = document.createElement("a");
        var blob = new Blob([xml.xml], {
          type: "text/xml",
        });
        var url = URL.createObjectURL(blob);
        element.href = url;
        element.setAttribute("download", "cadastro_fundos.xml");
        document.body.appendChild(element);
        element.click();
      });
  }
}
