<span *ngIf="!relatorioDinamico">
  <h1 class="title">Retorno acumulado {{ complementoTitulo }}</h1>
  <form>
    <mat-form-field>
      <input
        matInput
        [matDatepicker]="dp"
        placeholder="Ano"
        [formControl]="date"
        [min]="minDate"
      />
      <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
      <mat-datepicker
        #dp
        startView="multi-year"
        (yearSelected)="chosenYearHandler($event, dp)"
        panelClass="example-month-picker"
        [min]="minDate"
      >
      </mat-datepicker>
    </mat-form-field>

    <mat-form-field class="hidePrint" style="margin-left: 0.5em; width: 20%;">
      <mat-label>Meses para exibição</mat-label>
      <mat-select [formControl]="mesesExibicao" multiple>
        <mat-option *ngFor="let mes of mesesDisponiveis" [value]="mes">{{
          mes | date: "MMMM"
        }}</mat-option>
      </mat-select>
    </mat-form-field>
    <button mat-button class="hidePrint" (click)="toggleMeses()">
      {{
        mesesExibicao.value.length > 0 ? "Deselecionar todos" : "Selecionar todos"
      }}
    </button>

    <label class="hidePrint" style="margin-left: 0.5em;">Zoom:</label>
    <mat-slider
      class="hidePrint"
      min="0.5"
      max="1"
      step="0.01"
      value="1"
      [formControl]="zoom"
      color="primary"
    ></mat-slider>
    <button
      class="hidePrint"
      (click)="print()"
      mat-flat-button
      color="primary"
      style="float: right;"
    >
      <i class="fas fa-print"></i>Imprimir
    </button>
  </form>
</span>

<h2 *ngIf="laminaResumo" style="margin-bottom: 10px;">Comparativo com a Meta Atuarial</h2>
<table [style.zoom]="zoom.value" mat-table [dataSource]="registrosExibicao">
  <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
  <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></tr>

  <ng-container matColumnDef="mes">
    <th mat-header-cell *matHeaderCellDef>Mês</th>
    <td mat-cell *matCellDef="let retorno">
      <span class="mobile-label">Mês:</span>
      <span style="text-transform: uppercase;">{{
        retorno.periodo | date: "MMMM"
      }}</span>
    </td>
  </ng-container>

  <ng-container matColumnDef="saldoAnterior">
    <th mat-header-cell *matHeaderCellDef>Saldo anterior</th>
    <td mat-cell *matCellDef="let retorno">
      <span class="mobile-label" [style.width]="widthColumns">Saldo anterior:</span>
      {{ retorno.saldoAnterior | currency: "BRL" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="aplicacoes">
    <th mat-header-cell *matHeaderCellDef>Aplicações</th>
    <td mat-cell *matCellDef="let retorno">
      <span class="mobile-label" [style.width]="widthColumns" >Aplicações:</span>
      {{ retorno.aplicacoes | currency: "BRL" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="resgates">
    <th mat-header-cell *matHeaderCellDef [style.width]="widthColumns">Resgates</th>
    <td mat-cell *matCellDef="let retorno">
      <span class="mobile-label">Resgates:</span>
      {{ retorno.resgates | currency: "BRL" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="saldoAtual">
    <th mat-header-cell *matHeaderCellDef [style.width]="widthColumns">Saldo Atual</th>
    <td mat-cell *matCellDef="let retorno">
      <span class="mobile-label">Saldo atual:</span>
      {{ retorno.saldoAtual | currency: "BRL" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="retorno">
    <th mat-header-cell *matHeaderCellDef [style.width]="widthColumns">Retorno R$</th>
    <td mat-cell *matCellDef="let retorno">
      <span class="mobile-label">Retorno R$:</span>
      {{ retorno.retorno | currency: "BRL" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="percentualRetorno">
    <th mat-header-cell *matHeaderCellDef [style.width]="widthColumns">Efetivo %</th>
    <td mat-cell *matCellDef="let retorno">
      <span class="mobile-label">Efetivo %:</span>
      {{ retorno.retornoPercentual | percent: "1.4-4" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="meta">
    <th mat-header-cell *matHeaderCellDef [style.width]="widthColumns">Meta %</th>
    <td mat-cell *matCellDef="let retorno">
      <span class="mobile-label">Meta %:</span>
      {{ retorno.meta | percent: "1.4-4" }}
    </td>
  </ng-container>
  <ng-container matColumnDef="atingimento">
    <th mat-header-cell *matHeaderCellDef [style.width]="widthColumns">Atingimento %</th>
    <td mat-cell *matCellDef="let retorno">
      <span class="mobile-label">Atingimento %:</span>
      {{ retorno.atingimento | percent: "1.4-4" }}
    </td>
  </ng-container>
</table>

<br />
<table [style.zoom]="zoom.value" mat-table [dataSource]="totais">
  <tr mat-header-row *matHeaderRowDef="columnsToDisplayTotal"></tr>
  <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplayTotal"></tr>

  <ng-container matColumnDef="mes">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let retorno">
      Total
    </td>
  </ng-container>

  <ng-container matColumnDef="aplicacoes">
    <th mat-header-cell *matHeaderCellDef>Total aplicações</th>
    <td mat-cell *matCellDef="let retorno">
      <span class="mobile-label">Total aplicações:</span>
      {{ retorno.totalAplicacoes | currency: "BRL" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="resgates">
    <th mat-header-cell *matHeaderCellDef>Total Resgates</th>
    <td mat-cell *matCellDef="let retorno">
      <span class="mobile-label">Total Resgates:</span>
      {{ retorno.totalResgates | currency: "BRL" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="retorno">
    <th mat-header-cell *matHeaderCellDef>Total retorno R$</th>
    <td mat-cell *matCellDef="let retorno">
      <span class="mobile-label">Total retorno R$:</span>
      {{ retorno.totalRetornos | currency: "BRL"}}
    </td>
  </ng-container>

  <ng-container matColumnDef="percentualRetorno">
    <th mat-header-cell *matHeaderCellDef [style.width]="widthColumns">Efetivo %</th>
    <td mat-cell *matCellDef="let retorno">
      <span class="mobile-label">Efetivo %:</span>
      {{ retorno.totalRetornoPercentual | percent: "1.4-4" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="meta">
    <th mat-header-cell *matHeaderCellDef [style.width]="widthColumns">Meta %</th>
    <td mat-cell *matCellDef="let retorno">
      <span class="mobile-label">Meta %:</span>
      {{ retorno.totalMeta | percent: "1.4-4" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="atingimento">
    <th mat-header-cell *matHeaderCellDef [style.width]="widthColumns">Atingimento %</th>
    <td mat-cell *matCellDef="let retorno" >
      <span class="mobile-label">Atingimento %:</span>
      {{ retorno.atingimento | percent: "1.4-4" }}
    </td>
  </ng-container>
</table>
<br/>
<span *ngIf="laminaResumo" style="text-align: center;">
  No acumulado do ano a rentabilidade da carteira é de {{ totais[0]?.totalRetornoPercentual | percent: "1.4-4" }} enquanto que a
    meta acumulada no período é de {{ totais[0]?.totalMeta | percent: "1.4-4" }}, sendo assim o RPPS atingiu {{ totais[0]?.atingimento | percent: "1.4-4" }} da
    meta atuarial
</span>

