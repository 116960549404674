import { PercentPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ChartDataSets, ChartOptions } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import * as moment from 'moment';
import 'moment/locale/pt-br';
import { Label } from 'ng2-charts';
import { BehaviorSubject } from 'rxjs';
import { withLatestFrom } from "rxjs/operators";
import { Meta } from 'src/app/_models/benchmark.models';
import { MetaService } from 'src/app/_services/meta.service';


const nomesParametros  = {
  percentualMes: 'Percentual mês',
  percentualAno: 'Percentual ano',
  percentual3: 'Percentual 3M',
  percentual6: 'Percentual 6M',
  percentual12: 'Percentual 12M',
  percentual18:'Percentual 18M',
  percentual24: 'Percentual 24M'
}

@Component({
  selector: 'app-grafico-meta',
  templateUrl: './grafico-meta.component.html',
  styleUrls: ['./grafico-meta.component.scss']
})
export class GraficoMetaComponent implements OnInit {

  @Input("meta")
  meta:Meta;

  @Input("relatorioDinamico")
  relatorioDinamico:Boolean = false;

  dataEntrada$ = new BehaviorSubject<string>(null);
  @Input() set dataEntrada(value: string) {
    this.dataEntrada$.next(value)
  }

  @Output()
  loadingStatus = new EventEmitter<boolean>();

  labels: Label[] = []
  chartData: ChartDataSets[];

  public barChartPlugins = [pluginDataLabels];
  public barChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: { xAxes: [{}], yAxes: [{}] },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
        formatter: (value) => new PercentPipe('pt-BR').transform(value / 100, '1.2-4')
      }
    }
  };

  view:number[] = [];

  metaDados: Meta;
  dadosGrafico: any[];
  camposGrafico: string[];
  yScaleMax = 0;
  yScaleMin = 0;

  colorScheme = {
    domain: ['#1fd169', '#01579B', '#fbc02d', '#04bd98', '#a0d10f', '#c62828', '#e39b17']
  };

  constructor(private metaService: MetaService) { }

  ngOnInit() {
    if(this.relatorioDinamico){
      this.view = [1000, 500]
    }
    this.metaService.getMeta(this.meta.idMeta)
    .pipe(withLatestFrom(this.dataEntrada$))
    .subscribe(([meta, data]) => {
      this.metaDados = meta

      let dataLimite = moment()
      if(data){
        dataLimite = moment(data, 'DD/MM/YYYY');
      }


        this.dadosGrafico = [];
        this.camposGrafico = meta.camposGrafico ? meta.camposGrafico.split(",") : []


        this.chartData = []
        this.camposGrafico.forEach(campo => {

          let series: any = this.metaDados.quotes.filter(cota => {
            if(moment(cota.data).isSame(dataLimite, 'year')){
              return moment(cota.data).isSameOrBefore(dataLimite.endOf('month'))
            }
            return false
          }).map(c => c[campo]).reverse();


          this.chartData.push({ data:series, label: nomesParametros[campo] });


          this.labels = this.metaDados.quotes.filter(cota => {
            if(moment(cota.data).isSame(dataLimite, 'year')){
              return moment(cota.data).isSameOrBefore(dataLimite.endOf('month'))
            }
            return false
          }).map(c => moment(c.data).add(-3, 'h').format("MMMM")).reverse()


          const serie = { name: nomesParametros[campo], series: [] };
          this.metaDados.quotes.forEach(cota => {
            if(!moment(cota.data).isSame(dataLimite, 'year')){
              return
            }
            if(moment(cota.data).isAfter(dataLimite, 'month')) {
              return
            }

            if(cota[campo] > this.yScaleMax){
              this.yScaleMax = cota[campo]
            }

            if(cota[campo] < this.yScaleMin) {
              this.yScaleMin = cota[campo]
            }

            serie.series.push({ name: moment(cota.data).toDate(), value: cota[campo] });
          })

          this.dadosGrafico.push({...serie,series:serie.series.reverse()});



          console.log(this.chartData);
    })
    });


    this.dataEntrada$.subscribe(data => {
      this.loadingStatus.emit(false);
      const dataLimite = moment(data, 'DD/MM/YYYY');

      this.loadingStatus.emit(true);
    })
  }

  formatarY(valor) {
    return `${(valor).toFixed(3)}%`;
  }

  formatarX(valor) {
    return moment(valor).add(-3, 'hour').format('MMMM');
  }

  loadingChartData(dataLimite,meta){
      this.metaService.getMeta(this.meta.idMeta).subscribe(meta => {
        this.metaDados = meta

        this.dadosGrafico = [];
        this.camposGrafico = meta.camposGrafico ? meta.camposGrafico.split(",") : []


        this.chartData = []
        this.camposGrafico.forEach(campo => {

          let series: any = this.metaDados.quotes.filter(cota => {
            if(moment(cota.data).isSame(dataLimite, 'year')){
              return moment(cota.data).isSameOrBefore(dataLimite.endOf('month'))
            }
            return false
          }).map(c => c[campo]).reverse();


          this.chartData.push({ data:series, label: nomesParametros[campo] });


          this.labels = this.metaDados.quotes.filter(cota => {
            if(moment(cota.data).isSame(dataLimite, 'year')){
              return moment(cota.data).isSameOrBefore(dataLimite.endOf('month'))
            }
            return false
          }).map(c => moment(c.data).add(-3, 'h').format("MMMM")).reverse()


          const serie = { name: nomesParametros[campo], series: [] };
          this.metaDados.quotes.forEach(cota => {
            if(!moment(cota.data).isSame(dataLimite, 'year')){
              return
            }
            if(moment(cota.data).isAfter(dataLimite, 'month')) {
              return
            }

            if(cota[campo] > this.yScaleMax){
              this.yScaleMax = cota[campo]
            }

            if(cota[campo] < this.yScaleMin) {
              this.yScaleMin = cota[campo]
            }

            serie.series.push({ name: moment(cota.data).toDate(), value: cota[campo] });
          })

          this.dadosGrafico.push({...serie,series:serie.series.reverse()});
        })



        this.labels = this.metaDados.quotes.map(c => moment(c.data).add(-3, 'h').format("MMMM")).reverse()

        this.loadingStatus.emit(true);
      });
  }

}
