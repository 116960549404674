<h1 class="title">AUDESP - SP {{allowExport}}</h1>
<div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="15px" *ngIf="allowExport > 0">
  <button mat-raised-button color="primary" (click)="exportar_cadastros()">
    <mat-icon>playlist_add</mat-icon> EXPORTAR CADASTROS - XML
  </button>

  <button mat-raised-button color="primary" (click)="exportar_fundos()">
    <mat-icon>playlist_add</mat-icon> EXPORTAR FUNDOS - XML
  </button>
</div>
<br />



<div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="15px">
  <form>
    <mat-form-field>
      <input matInput [matDatepicker]="dp" [max]="atual" placeholder="Mês/Ano" [formControl]="date" min="2022-01-01" />
      <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
      <mat-datepicker #dp startView="multi-year" (yearSelected)="chosenYearHandler($event)"
        (monthSelected)="chosenMonthHandler($event, dp)" panelClass="example-month-picker">
      </mat-datepicker>
    </mat-form-field>
  </form>
</div>
<div [hidden]="conjunto">
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>


<div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="15px">
  <table>
    <thead>
      <tr>
        <th><strong>Linha</strong></th>
        <th><strong>Código</strong></th>
        <th><strong>Fundo</strong></th>
        <th><strong>Segmento</strong></th>
        <th><strong>Situação</strong></th>
        <th>Ações</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let fundo of conjunto; let i = index">
        <td>{{i+1}} {{fundo.incluir_xml?"XML":"S/XML"}}</td>
        <td><span>{{fundo.codigoFundo}}</span>/<span>{{ fundo.codigoAlternativo }}</span>
        <td>
          <p>
            {{fundo.nome}}
          <p>
          <p>
            {{fundo.cnpj}}

          </p>

        </td>
        <td>{{fundo.classe}}</td>
        <td>{{fundo.status > 0? "Ok":"Pendente" }}</td>
        <td *ngIf="fundo.status === 0">


          <button type="button" mat-icon-button color="primary" matTooltip="Informações do Fundo"
            (click)="abrirRIPP(fundo)">
            <i class="fas fa-plus"></i>
          </button>
        </td>
        <td *ngIf="fundo.status>0">



          <button type="button" mat-icon-button color="primary" matTooltip="Editar Informações do Fundo"
            (click)="editarRIPP(fundo)">
            <i class="fas fa-eye"></i>
          </button>


          <button type="button" mat-icon-button color="primary" matTooltip="Cadastro do Fundo"
            (click)=abrirCadastro(fundo)>

            <i class="fas fa-edit"></i>
          </button>

        </td>
      </tr>
    </tbody>
  </table>




</div>