import { Component, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { MatDatepicker } from "@angular/material/datepicker";
import { ActivatedRoute } from "@angular/router";
import * as moment from "moment";
import { Moment } from "moment";
import { delay } from "rxjs/operators";
import { RetornoAnual } from "src/app/_models/carteira.model";
import { AuthService } from "src/app/_services/auth.service";
import { CarteiraService } from "src/app/_services/carteira.service";
import { ClienteService } from "src/app/_services/cliente.service";

export const MY_FORMATS = {
  parse: {
    dateInput: "YYYY",
  },
  display: {
    dateInput: "YYYY",
    monthYearLabel: "YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "YYYY",
  },
};

@Component({
  selector: "app-relatorio-retornos-anual",
  templateUrl: "./relatorio-retornos-anual.component.html",
  styleUrls: ["./relatorio-retornos-anual.component.scss"],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class RelatorioRetornosAnualComponent implements OnInit {
  atual = moment();
  minDate: Date;
  date = new FormControl(moment());
  columnsToDisplay = [
    "cnpj",
    "nome",
    "saldoAnterior",
    "aplicacoes",
    "resgates",
    "saldoAtual",
    "retorno",
    "percentualRetorno",
  ];
  registros: RetornoAnual[] = [];
  registrosExibicao: RetornoAnual[] = [];
  complementoTitulo: string = "";

  idCliente: number;
  totais = [];
  zoom = new FormControl(1);
  mesesExibicao = new FormControl([]);
  mesesDisponiveis = [];

  constructor(
    private carteiraService: CarteiraService,
    private clienteService: ClienteService,
    private route: ActivatedRoute,
    private authService: AuthService
  ) {
    this.idCliente = +route.snapshot.paramMap.get("id");
  }

  ngOnInit() {
    this.minDate = new Date(2021, 12, 31);
    window.onafterprint = (e) => this.authService.willPrint$.next(false);
    this.authService.willPrint$.pipe(delay(500)).subscribe((willPrint) => {
      if (willPrint) {
        window.print();
      }
    });
    if (this.idCliente) {
      this.clienteService.buscarClientePorId(this.idCliente).subscribe((c) => {
        this.complementoTitulo = ` - Cliente: ${c.nome}`;
      });
    }
  }

  chosenYearHandler(normalizedYear: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue.startOf("month"));
    datepicker.close();
    this.retrieveRetornos(ctrlValue);
  }

  retrieveRetornos(date: Moment) {
    this.carteiraService
      .relatorioRetornosAnual(this.idCliente, date)
      .subscribe((v) => {
        this.registros = v;
      });
  }

  print() {
    this.authService.willPrint$.next(true);
  }
}
